import React , { Component } from 'react';
import { connect } from 'react-redux';
import { doAddText } from 'actions/workspace';

import { INITIAL_STATE_TEXT }  from 'initial_states/workspace';
import { getCurrentFaceTextItems } from 'selectors/items';
import TextElement from './TextElement';



class PanelText extends Component {
	constructor(props) {
		super(props);
     
		this.state = INITIAL_STATE_TEXT;
		this.onSubmit = this.onSubmit.bind(this);
	}

    handleClickAddTexte = (e) => {
    	e.preventDefault();
    	const state = Object.assign({}, INITIAL_STATE_TEXT);
    	this.props.doAddText(state);
    };

    onSubmit(event) {
    	const  state  = this.state;
    	if (state) {
    		this.props.doAddText(state);
    		this.setState({params:{text:''}});
    	}
    	event.preventDefault();
    }

    onChange(event, localID) {
    	const { value } = event.target;
    	this.setState({params: {text: value}});
    }

    render() {
    	return (
    		<div>
    			<a className="btn_medianet btn_product px-40" href="{}" onClick={(event) => this.handleClickAddTexte(event)} title="Ajouter une zone de texte">Ajouter une zone de texte</a>
    			<form className="form_addtxt_editeur" method="post" action="">
    				{
    					(this.props.state || []).map((ele, index )=> (<TextElement ele = {ele} key={index} />))
    				}
    			</form>
    		</div>
    	);}
}

  
const mapStateToProps = state => ({
	state: getCurrentFaceTextItems(state.workspaceState.present),
});

const mapDispatchToProps = dispatch => ({
	doAddText: data => dispatch(doAddText(data)),
});
  
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PanelText);