import React ,{ Component } from 'react';

  
class Image extends Component {
	constructor(props) {
		super(props);
		this.state = {
			src: '',
			err: null
		};
	}

	render() {
		const style = { 
			width: (isNaN(this.props.ele.size.width)) ? 'auto' : this.props.ele.size.width + 'px', 
			height: (isNaN(this.props.ele.size.height)) ? 'auto' : this.props.ele.size.height + 'px',
			WebkitFilter: 'hue-rotate(' +  this.props.ele.params.filterCss.hue + 'deg) saturate('+ this.props.ele.params.filterCss.saturate +') brightness('+ this.props.ele.params.filterCss.brighten +'%)'
		 };

		return (
			<img  draggable="false" src={this.props.ele.params.src} style={style} alt="WEPRINT" />
		);
	}
    
}

export default Image;