import React , { Component } from 'react';
import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { Link } from 'react-router-dom';
import { doSetInfoRecordedState, doSetZoom } from 'actions/workspace';






class TopRightColEditeur extends Component {
	constructor(props) {
		super(props);
		this.state = {zoom : this.props.zoom};
	}


	handelZoomIn () {
		let _zoom =  this.props.zoom;
		this.props.doSetZoom({zoom : +(_zoom + 0.2).toPrecision(2)});
	}
	handelZoomOut () {
		let _zoom =  this.props.zoom;
		this.props.doSetZoom({zoom : +(_zoom -= 0.2).toPrecision(2)});
	}
 
	


	


	


	render() {
		return (

			
			<div className="row_middle_editeur justify-content-between d-flex pl-20">
				<div className="bloc_preview_editeur">
					<p className="name_doc_editeur pr-40 mr-40">{ (this.props.items.title) ? this.props.items.title : 'Document sans titre'}</p>
					<div className="prev_doc_editeur action_overlay" onClick={(Event) => this.props.showPreview(Event)}>
						<span className="prev_doc_icon"><img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/ico_prev.png'} alt="" /></span>
						<span className="prev_doc_caption"> Prévisualiser</span>
					</div>
				</div>
				<div className="bloc_zoom_editeur">
					<span onClick={() => this.handelZoomOut()} className="zoom_minus" id="zoom-minus">Zoom -</span>
					<div className="zoom_apercu mx-20">
						<input 
							readOnly
							value={ this.props.zoom} 
							type="text" 
							className="form-control" 
							id="zoom-value" />
						<span>%</span>
					</div>
					<span onClick={() => this.handelZoomIn()} className="zoom_plus" id="zoom-plus">Zoom +</span>
				</div>
				<div className="bloc_save_editeur d-flex">
					<div className="toolbar_button d-flex annuler_button" onClick={this.props.onUndo}>
						<span className="toolbar_button_icon"><img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/ico_annuler.png'} alt="" /></span>
						<span className="toolbar_button_caption">Annuler</span>
					</div>
					<div className="toolbar_button d-flex retablir_button" onClick={this.props.onRedo}>
						<span className="toolbar_button_icon"><img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/ico_retablir.png'} alt="" /></span>
						<span className="toolbar_button_caption">Rétablir</span>
					</div>

					{/* <div className="toolbar_button d-flex save_button action_overlay" onClick={() => printDocument(this.props.items)}>
						<span className="toolbar_button_icon"><img src="assets/images/png/icon_save.png" alt="" /></span>
						<span className="toolbar_button_caption">PDF</span>
					</div> */ }

					<div className="toolbar_button d-flex save_button action_overlay">
						<span className="toolbar_button_icon"><img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/icon_save.png'} alt="" /></span>
						<Link className="toolbar_button_caption" to="/documents/">Documents</Link>
					</div> 

					<div className="toolbar_button d-flex save_button action_overlay" onClick={(Event) => this.props.doRenderPdf(Event)}>
						<span className="toolbar_button_icon"><img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/icon_save.png'} alt="" /></span>
						<span className="toolbar_button_caption">PDF</span>
					</div>

					<div className="toolbar_button d-flex save_button action_overlay" onClick={() => this.props.doSaveDocument(this.props.items)}>
						<span className="toolbar_button_icon"><img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/icon_save.png'} alt="" /></span>
						<span className="toolbar_button_caption">Enregistrer</span>
					</div>
					<button className="finish_button action_overlay">Terminer</button>
				</div>


			
				
			
			
			
				
			
			
			</div>);}
}
  

const mapDispatchToProps = dispatch => ({
	doSetInfoRecordedState: data => dispatch(doSetInfoRecordedState(data)),
	doSetZoom : data => dispatch(doSetZoom(data)),
	onUndo: () => dispatch(ActionCreators.undo()),
	onRedo: () => dispatch(ActionCreators.redo ())
});

const mapStateToProps = state => ({
	items: state.workspaceState.present,
	zoom: state.workspaceState.present.zoom
});



export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TopRightColEditeur);
  
