

import { FRONT, BACK } from 'constants/faces';

const getTextItems = (state ) => {
	if (!state.items) { return []; }
	return state.items.filter(ele => (ele.type === 'TEXT'));
};
	

const getFrontTextItems = (state ) => {
	if (!state.items) { return []; }
	return state.items.filter(ele => (ele.type === 'TEXT' && ele.face === FRONT));
};
	

const getBackTextItems = (state ) => {
	if (!state.items) { return []; }
	return state.items.filter(ele => (ele.type === 'TEXT' && ele.face === BACK));
};
	

const getFrontItems = (state) => {
	if (!state.items) { return []; }
	return state.items.filter(ele => (ele.face === FRONT));
};
	


const getBackItems = (state ) => {
	if (!state.items) { return []; }
	return 	state.items.filter(ele => (ele.face === BACK));
};


const getCurrentFaceTextItems = (state ) => {
	if (!state.items) { return []; }
	return state.items.filter(ele => (ele.type === 'TEXT' && ele.face === state.activeFace));
};
	

const getCurrentFaceItems = (state ) => {
	if (!state.items) { return []; }
	return state.items.filter(ele => (ele.face === state.activeFace));
};
	


const getCurrentHtmlFace = (state ) => {

	return (state.html[state.activeFace])
		? state.html[state.activeFace]
		: null;
};
	
export {
	getTextItems,
	getFrontItems,
	getBackItems,
	getFrontTextItems,
	getBackTextItems,
	getCurrentFaceItems,
	getCurrentFaceTextItems,
	getCurrentHtmlFace
};