import React , { Component } from 'react';
import { connect } from 'react-redux';
import { doEditText } from 'actions/workspace';
import ContentEditable from 'react-contenteditable';

class Edit extends Component {

	constructor(props, ctx) {
		super(props, ctx);
		this.state = {};
		this.contentEditable = React.createRef();
		this.handleChange = this.handleChange.bind(this);
	}
	
	
	handleChange(event) {
    	const { value } = event.target;
    	const newStateText = Object.assign({},this.props.ele, {...this.props.ele, params:{text: value}});
    	this.props.doEditText(newStateText);
	}

	

	componentDidMount(){
		// this.nameInput.focus(); 
	 }
	 

	render() {
    	const text = this.props.ele.params.text;
    	return (
    		<div className="form-group">
    			{/*<textarea ref={(input) => { this.nameInput = input; }} type="text" className="form-control" id="cart_header" value={text} onChange={(event) => this.onChange(event)} placeholder={text}>{text}</textarea>*/}
    		
				<ContentEditable
					className="contenteditable form-control"
					tagName="div"
					innerRef={this.contentEditable}
					html={text} 
					disabled={false}      
					onChange={this.handleChange}
				/>
			
			</div>
    	);}
}



const mapDispatchToProps = dispatch => ({
	doEditText: data => dispatch(doEditText(data)),
});
  
export default connect(
	null,
	mapDispatchToProps
)(Edit);