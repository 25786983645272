import React , { Component } from 'react';
import { connect } from 'react-redux';
import { Overlay, Popover} from 'react-bootstrap';


import  {
	Tableau, 
	Text, 
	Image,
	Star,
	Square,
	Circle,
	Arrow,
	Line,
	Triangle}  from '../Models';
import {
	ToolsForme,
	ToolsTableau,
	ToolsTexte,
	ToolsImage
} from '../PanelsTools';
import { 
	setSize,
	setPosition,
	setLiveSizePosition,
	toggleSelected,
	unselectAll
} from 'actions/workspace';
import {Rnd} from 'react-rnd';
import './viewer.css';
  

const components = {
	TABLE: {layout : Tableau, tools : ToolsTableau},
	TEXT: {layout : Text, tools : ToolsTexte},
	IMAGE: {layout : Image, tools : ToolsImage},
	STAR: {layout : Star, tools : ToolsForme},
	SQUARE: {layout : Square, tools : ToolsForme},
	CIRCLE: {layout : Circle, tools : ToolsForme},
	ARROW: {layout : Arrow, tools : ToolsForme},
	LINE: {layout : Line, tools : ToolsForme},
	TRIANGLE: {layout : Triangle, tools : ToolsForme},
};


const style = {
	/*display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',*/
};
  


class Viewer extends Component {
	constructor(props) {
		super(props);
		this.state = { target : null, isOpenPopover : this.props.ele.selected};
		this.setSize = this.setSize.bind(this);
		this.myRef = React.createRef();

		
	}

	componentDidUpdate() {}
	
	/**
     * 
     * @param {*} localID 
     * @param {*} data 
     */
	setPosition(localID, data) {
		if (this.props.ele.position.x !== data.x || this.props.ele.position.x !== data.y) {
			this.props.setPosition({localID: localID, data: data});
		} 
	}

	/**
     * 
     * @param {*} localID 
     * @param {*} data 
     */
	setSize (localID, data) {
		this.props.setSize({localID: localID, data: data});
	}

	/**
     * 
     * @param {*} localID 
     * @param {*} data 
     */
	setLiveSizePosition (localID, data) {
		this.props.setLiveSizePosition({localID: localID, ...data});
	}

	/**
     * 
     * @param {*} localID 
     */
	toggleSelected (event) {
		event.preventDefault();
		if (this.props.ele.selected === false) {
			this.props.toggleSelected({localID: this.props.ele.localID});
		}
	}

	
	render() {
		const ele = this.props.ele;
		const TagModelName = components[ele.type].layout;
		const TagToolsName = components[ele.type].tools;



		return (

			
			
			<Rnd
				//scale = {this.props.zoom}
				ref = {this.myRef}
				disableDragging={ele.disableDragging}
				enableUserSelectHack={true}
				//  onContextMenu={(event) => this.toggleSelected(event)}
				default={{
					x: 0,
					y: 0,
					width: 'auto',
					height: 'auto',
				}}
				position={ele.position}
				size={ele.size}
				lockAspectRatio = {ele.lockAspectRatio}
				resizeGrid={[1,1]}
				style={style}
				onDrag = {( e , d ) => { 
					this.setState({isOpenPopover : false});
				}}
				onDragStop = {( e , d ) => { 
					this.setState({isOpenPopover : true});
					this.setPosition (ele.localID, {x :  d.x , y :  d.y });
				}}
				onResize = {( e , direction , ref , delta , position ) => {
					this.setState({isOpenPopover : false});
					this.setLiveSizePosition (ele.localID, {
						size : {
							width :  ref.offsetWidth ,
							height :  ref.offsetHeight 
						},
						position : position
					});
				}}
				onResizeStop = {( e , direction , ref , delta , position ) => {
					this.setState({isOpenPopover : true});
					this.setSize (ele.localID, {
						width :  ref.offsetWidth ,
						height :  ref.offsetHeight 
					});
				}}
				resizeHandleClasses = {{      
					bottomLeft: 'ui-resizable-handle ui-resizable-sw',
					bottomRight: 'ui-resizable-handle ui-resizable-se',
					topLeft: 'ui-resizable-handle ui-resizable-nw',
					topRight: 'ui-resizable-handle ui-resizable-ne',
					left: 'ui-resizable-handle ui-resizable-w',
					right: 'ui-resizable-handle ui-resizable-e',
					bottom: 'ui-resizable-handle ui-resizable-s',
					top: 'ui-resizable-handle ui-resizable-n',
				}}
				onDoubleClick={(event) => this.toggleSelected(event)}
				enableResizing = {ele.selected ? ele.enableResizing : null}
				key={this.props.index}> 
				<TagModelName parentRef={this.myRef} setSize={this.setSize}  ele={ele} index={this.props.index} />
				<Overlay
					show={this.state.isOpenPopover === false ? this.state.isOpenPopover : ele.selected}
					placement="top"
					target={this}
					containerPadding={10}
				>
					<Popover className="tooltipClass edit-widget">
						<TagToolsName  ele = {ele}  />
					</Popover>
				</Overlay>
			</Rnd>      
		);
	}
}
  

  
const mapDispatchToProps = dispatch => ({
	setSize: data => dispatch(setSize(data)),
	setPosition: data => dispatch(setPosition(data)),
	setLiveSizePosition: data => dispatch(setLiveSizePosition(data)),
	toggleSelected: data => dispatch(toggleSelected(data)),
	unselectAll: data => dispatch(unselectAll(data))
});

export default connect(
	null,
	mapDispatchToProps
)(Viewer);