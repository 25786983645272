import React , { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import Color from './components/Color';
import Crop from './components/Crop';
import Rotate from './components/Rotate';
import Empty from './components/Empty';

import { doDelete,
	disableDragging,
	enableDragging,
	doDuplicate} from 'actions/workspace';


class ToolsImage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);

		this.state = {
			selectedAction : null,
		};
	}


	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}

	showActionPanel = (event, action) => {
		event.preventDefault(); 
		action = (this.state.selectedAction !== action) ? action : null;
		this.setState({selectedAction : action});
		if (this._componentsAction[action]) {
			this.props.disableDragging({localID : this.props.ele.localID});
		} else {
			this.props.enableDragging({localID : this.props.ele.localID});
		}
	}

	_componentsAction = {
		'Color' : Color, 
		'Crop' : Crop,
		'Rotate' : Rotate,
	};

	render() {

		const settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 4,
			variableWidth: true,
			className: 'actions'
		};
		const action = this.state.selectedAction;
		const ComponentAction = (this._componentsAction[action]) ? this._componentsAction[action] : Empty;
       
		return (
           
			<div className={(this.state.selectedAction !== null) ? 'edit-widget slide-up-show' : 'edit-widget'}>
				<div className="slip-button"></div>
				<div className="elements-wrapper">

					<div id="option-data">
						<ComponentAction ele={this.props.ele} />
					</div>
					<div className="arrows arrow-left">
						<a id="widget-left" href="{}" onClick={(event) => { event.preventDefault(); this.previous();}}><i className="icon-chevron-right"></i></a>
					</div>
					<div className="actions-wrapper">

						<Slider ref={c => (this.slider = c)} {...settings}>

							<button className={(this.state.selectedAction === 'Color') ? 'action with-text show active' : 'action with-text show'} onClick={(event) => { this.showActionPanel(event, 'Color'); }}>
								<span>Coleur</span>
								<i className="icon-color-wheel"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span><span className="path10"></span><span className="path11"></span><span className="path12"></span><span className="path13"></span><span className="path14"></span><span className="path15"></span><span className="path16"></span></i>
							</button>
							
							<button className={(this.state.selectedAction === 'Crop') ? 'action with-text show active' : 'action with-text show'} onClick={(event) => { this.showActionPanel(event, 'Crop'); }}>
								<span>Recadrer</span>
								<i className="icon-crop"></i>
							</button>

							<button className={(this.state.selectedAction === 'Rotate') ? 'action with-text show active' : 'action with-text show'} onClick={(event) => { this.showActionPanel(event, 'Rotate'); }}>
								<span>Rotation</span>
							</button>

							<button className="action" onClick={() => { this.props.doDuplicate(this.props.ele); }}>
								<i className="icon-duplicate"></i>
							</button>
							
							<button className="action" onClick={() => {this.props.doDelete({localID: this.props.ele.localID});}}>
								<i className="icon-delete"></i>
							</button>
						</Slider>

					</div>

					<div className="arrows arrow-right">
						<a id="widget-right" href="{}" onClick={(event) => { event.preventDefault(); this.next();}}><i className="icon-chevron-right"></i></a>
					</div>


				</div>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	doDelete: data => dispatch(doDelete(data)),
	doDuplicate: data => dispatch(doDuplicate(data)),
	disableDragging: data => dispatch(disableDragging(data)),
	enableDragging: data => dispatch(enableDragging(data)),
});

export default connect(
	null,
	mapDispatchToProps
)(ToolsImage);


