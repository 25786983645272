import React from 'react';
import Modal from 'react-modal';
import { 
	PanelForme,
	PanelText,
	PanelImage } from './PanelsSettings';

class LeftColEdituer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isPaneOpenLeft: false,
			activePanel : null
		};
	}

	componentDidMount() {
		Modal.setAppElement(this.el);
	}

	togglePanel (activePanel) {
		this.setState({ activePanel: activePanel });
	}


	handleImageChange(e) {
		e.preventDefault();
    
		let reader = new FileReader();
		let file = e.target.files[0];
    
		reader.onloadend = () => {
			this.setState({
				params: {
					src: reader.result
				}
			});
		};
  
		reader.readAsDataURL(file);
	}

	render() {
		return <div className="left_col_editeur" ref={ref => this.el = ref}>
			<div className="logo_editeur pt-15">
				<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/logo_editeur.png'} alt="" />
			</div>
			<div className="container_left_col pt-75 text-center">
				<div className="left_elem_editeur container_texte" onClick={ () => this.togglePanel('_text') } >
					<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/ico_texte.png'} alt="" />
					<span className="text_ico_left">
            Texte
					</span>
				</div>

				<div className="left_elem_editeur container_form" onClick={ () => this.togglePanel('_forme') }>
					<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/ico_forme.png'} alt=""  />
					<span className="text_ico_left">
            Forme
					</span>
				</div>

				<div className="left_elem_editeur container_image" onClick={ () => this.togglePanel('_image') }>
					<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/ico_image.png'} alt="" />
					<span className="text_ico_left">
            Image
					</span>
				</div>
				<div className="left_elem_editeur container_graphique" onClick={ () => this.togglePanel('_graphique') }>
					<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/ico_graphique.png'} alt="" />
					<span className="text_ico_left">
            Graphique
					</span>
				</div>
			</div>
			<div className="row_slide_element">
				<div className={'slide_texte p-30 slide_element_editeur' + (this.state.activePanel === '_text' ? ' slide_left' : '')}>
					<span className="remove_slide"><img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/remove_slide.png'} alt="" onClick={ () => this.togglePanel(null) }/></span>
					<h4 className="title_text_slide">Saisissez votre texte</h4>
					<PanelText />
				</div>
				<div className={'slide_forme p-30 slide_element_editeur' + (this.state.activePanel === '_forme' ? ' slide_left' : '')}>
					<span className="remove_slide"><img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/remove_slide.png'} alt ="" onClick={ () => this.togglePanel(null) } /></span>
					<h4 className="title_text_slide">Choisissez une forme</h4>
					<PanelForme />
				</div>
				<div className={'slide_image p-30 slide_element_editeur' + (this.state.activePanel === '_image' ? ' slide_left' : '')}>
					<span className="remove_slide"><img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/remove_slide.png'}  alt= "" onClick={ () => this.togglePanel(null) } /></span>
					<h4 className="title_text_slide">Ajouter une image</h4>
					<PanelImage />

				</div>
				<div className={'slide_graphique p-30 slide_element_editeur' + (this.state.activePanel === '_graphique' ? ' slide_left' : '')}>
					<span className="remove_slide"><img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/remove_slide.png'}  alt= ""  onClick={ () => this.togglePanel(null) } /></span>
					<h4 className="title_text_slide">Ajouter un graphique</h4>
					<div className="container_form_editeur d-flex">
						<div className="box_form_editeur box_form_graph_forme d-flex">
							<span className="picto_form_editeur mb-10 picto_graphformes"> </span>
							<span className="caption_form_editeur">Formes</span>
						</div>

						<div className="box_form_editeur box_form_graph_ligne d-flex">
							<span className="picto_form_editeur mb-10 picto_ligne"> </span>
							<span className="caption_form_editeur">Ligne</span>
						</div>
						<div className="box_form_editeur box_form_graph_cadres d-flex">
							<span className="picto_form_editeur mb-10 picto_graphcadres"> </span>
							<span className="caption_form_editeur">cadres</span>
						</div>

						<div className="box_form_editeur box_form_graph_icones d-flex">
							<span className="picto_form_editeur mb-10 picto_graphicones"> </span>
							<span className="caption_form_editeur">Icônes</span>
						</div>
						<div className="box_form_editeur box_form_graph_reseaux d-flex">
							<span className="picto_form_editeur mb-10 picto_graphtreseaux"> </span>
							<span className="caption_form_editeur">Réseaux</span>
						</div>
						<div className="box_form_editeur box_form_graph_autres d-flex">
							<span className="picto_form_editeur mb-10 picto_graphautres"> </span>
							<span className="caption_form_editeur">Autres</span>
						</div>
					</div>
					<div className="box_slide_forms container_formes_graph">
						<div className="d-flex box_slide_graphs">
							<h5 className="title_box_graph">Formes</h5>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-4.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-5.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-6.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-1.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-2.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-3.png'}  alt= "" />
							</div>

						</div>
					</div>
					<div className="box_slide_forms container_ligne_graph">
						<div className="d-flex box_slide_graphs">
							<h5 className="title_box_graph">Ligne</h5>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-3.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-4.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-1.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-2.png'}  alt= "" />
							</div>

							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-5.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-6.png'}  alt= "" />
							</div>
						</div>
					</div>
					<div className="box_slide_forms container_cadres_graph">
						<div className="d-flex box_slide_graphs">
							<h5 className="title_box_graph">Cadres</h5>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-1.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-2.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-3.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-4.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-5.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-6.png'}  alt= "" />
							</div>
						</div>
					</div>
					<div className="box_slide_forms container_icones_graph">
						<div className="d-flex box_slide_graphs">
							<h5 className="title_box_graph">Icônes</h5>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-2.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-3.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-4.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-5.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-1.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-6.png'}  alt= "" />
							</div>
						</div>
					</div>
					<div className="box_slide_forms container_reseaux_graph">
						<div className="d-flex box_slide_graphs">
							<h5 className="title_box_graph">Réseaux</h5>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-3.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-4.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-5.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-1.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-2.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-6.png'}  alt= "" />
							</div>
						</div>
					</div>
					<div className="box_slide_forms container_autres_graph">
						<div className="d-flex box_slide_graphs">
							<h5 className="title_box_graph">Autres</h5>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-3.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-1.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-2.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-6.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-4.png'}  alt= "" />
							</div>
							<div className="container_box_graph">
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/graph-5.png'}  alt= "" />
							</div>

						</div>
					</div>
				</div>

			</div>
		</div>;

	}
}


export default LeftColEdituer;