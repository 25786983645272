import React , { Component } from 'react';
import { connect } from 'react-redux';
import { doEditText, doDelete } from 'actions/workspace';
import ContentEditable from 'react-contenteditable';

class TextElement extends Component {

	constructor(props) {
		super(props);
		this.contentEditable = React.createRef();
		this.handleChange = this.handleChange.bind(this);
		this.state = {
			inputValue : ''
		  };
	  };

	
	handleChange(value) {
    	const newStateText = Object.assign({},this.props.ele, {...this.props.ele, params:{text: value}});
    	this.props.doEditText(newStateText);
	}

	render() {
		const {text} = this.props.ele.params;

    	return (
    		<div className="form-group wrapper-contenteditable" >
				<button className="delete-contenteditable" onClick={() => {this.props.doDelete({localID: this.props.ele.localID});}}><i className="icon-delete"></i></button>
	
	
				<ContentEditable
					className="contenteditable form-control"
					tagName="div"
					innerRef={this.contentEditable}
					html={text} 
					disabled={false}      
					onChange={event => this.handleChange(event.target.value)}
				/>
    		</div>
    	);}
}



const mapDispatchToProps = dispatch => ({
	doEditText: data => dispatch(doEditText(data)),
	doDelete: data => dispatch(doDelete(data)),
});
  
export default connect(
	null,
	mapDispatchToProps
)(TextElement);