import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import {  pdfGenerator } from '../api/workspace';


const generatePdf = (document) => {

	// API
	const parserApi = new DOMParser();
	const wrapperFront = parserApi.parseFromString( document.html['FRONT'], 'text/html');
	const wrapperBack = parserApi.parseFromString( document.html['BACK'], 'text/html');

	return pdfGenerator({
		 FRONT: wrapperFront.body.firstChild.outerHTML,
		 BACK: wrapperBack.body.firstChild.outerHTML
	});
};


class Pdf extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			previewPdf: '',
			compiled : false,
			loaded : false
		};
	}


	componentDidMount() {
		generatePdf(this.props.document)
			.then(res => {
				this.setState({ previewPdf: res.data.url, compiled : true });
			
			});
	 }
	
	  componentDidUpdate() {
		if (this.props.open === false && this.state.compiled === true) {
			this.setState({front: '', back: '', compiled: false});
		}
		
		if (this.state.compiled === false && this.props.open && this.props.document.html) {

			generatePdf(this.props.document)
				.then(res => {
					this.setState({ previewPdf: res.data.url, compiled : true });
			
				});

		}

	}

	

	render() {

		const previewPdf = (this.state.previewPdf !== '')
			? (
				<div style={{margin: '30px'}}>
					<p style={{margin: '30px'}}>
				Votre épreuve est prête. <a href={this.state.previewPdf} target="_blank" rel="noopener noreferrer" >Visualisez-la maintenant</a>. 
				Nous vous l'enverrons également par courriel pour que vous puissiez la consulter à un autre moment.
					</p>
			  </div>
			)
			: (
				<div><p>Création de l'épreuve PDF en cours</p><Loader 
					type="ThreeDots"
					color="#00BFFF"
					height="100"	
					width="100"
				/> </div>);


		return(
			<div className="overlay opacity_bg open" id="overlay_editeur">
				<div className="box-preview-editeur text-center" style={{display: 'block'}}>
					<div className="row_top_preview pt-80 justify-content-between d-flex">
						<h1 className="title_preview">Obtenir une épreuve PDF</h1>
						<div className="container_btn_top_preview">
							<button onClick={this.props.toggleDrawer} className="btn_medianet btn_default reset_overlay px-20 mr-10"><i className="zmdi zmdi-arrow-left"></i> Retour</button>
							<button className="btn_medianet btn_default px-20 mr-10">Demander un bon à tirer</button>
							<button className="btn_medianet btn_product px-20 mr-10">Terminer et commander</button>
						</div>
					</div>
					<div className="row_bottom_preview d-flex">
						<div className="col-12 col-md-12 col-lg-12 mr-30">
							<div className="block_preview px-55 py-70">
								
								{previewPdf}
								
							</div>
						</div>
					</div>
					<div className="row_desc_preview text-left mt-30">
						<div className="row">
							<div className="col-12 col-md-12 col-lg-12">
								<h4 className="title_desc_preview mb-15">Veuillez vérifier dans votre fichier les points suivants :</h4>
								<p className="caption_desc_preview">
                            Contenus et orthographe corrects<br />
                            Textes bien lisibles<br />
                            Images claires, non floues<br />
                            Elements suffisamment loin du bord<br />
                            Pas de cadre, pas de surimpression
								</p>
							</div>
						</div>
					</div>
					<div className="besoin_aide pt-15">
						<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/picto_tel.png'} alt="" />
						<p className="caption_besoin_aide">Besoin<span><br />d'aide ?</span></p>
					</div>
				</div>
			</div>

		);
	}
}




const mapStateToProps = state => ({
	document: state.workspaceState.present
});



export default connect(
	mapStateToProps,
	null
)(Pdf);
  


