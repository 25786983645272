import React , { Component } from 'react';
import { connect } from 'react-redux';
import { doEditImage } from 'actions/workspace';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import getCroppedImg from 'utils/getCroppedImg';

class Crop extends Component {

	constructor(props, ctx) {
		super(props, ctx);
		this.state = {
			src : '',
			crop: (this.props.ele.crop.x !== undefined)
				? this.props.ele.crop
				: {
					aspect: this.props.ele.size.width/this.props.ele.size.height,
					x: 10,
					y: 10,
					width: 80
			  }
		};

	}


	onCropComplete = crop => {

		const imageObj = {
			src : this.props.ele.params.srcRef,
			width: this.props.ele.size.width,
	  		height: this.props.ele.size.height,

		};

		const img = getCroppedImg(imageObj, crop, this.props.ele.params.mime);
		const params = {...this.props.ele.params, src : img};
		const _crop = {...this.props.ele.crop, ...crop, aspect: this.props.ele.size.width/this.props.ele.size.height};
		const newState = Object.assign({}, this.props.ele, {...this.props.ele, params : params, crop: _crop});
		this.props.doEditImage(newState);
	  }
	
	  onCropChange = crop => {
	  	this.setState({ crop });
	  }

	  onImageLoaded = (event) => {
	  }

	  render() {
	  
    	return (
	  		<div>
	  		<ReactCrop 
	  			src={this.props.ele.params.srcRef} 
				  crop={this.state.crop}
	  				imageStyle={
	  					{
	  						width: this.props.ele.size.width + 'px',
	  						height: this.props.ele.size.height + 'px',
	  						maxHeight: 'none'
	  					}}
	  			onComplete={this.onCropComplete}
				  onChange={this.onCropChange}
				  onImageLoaded={this.onImageLoaded}
						 />




						 </div>
						 
			
    	);}
}



const mapDispatchToProps = dispatch => ({
	doEditImage: data => dispatch(doEditImage(data)),
});
  
export default connect(
	null,
	mapDispatchToProps
)(Crop);