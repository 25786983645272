import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { screenshot } from '../api/workspace';


const generateImage = (document) => {

	const allPromises = [];

	// API
	const parserApi = new DOMParser();
	const wrapperFront = parserApi.parseFromString( document.html['FRONT'], 'text/html');
	const wrapperBack = parserApi.parseFromString( document.html['BACK'], 'text/html');

	allPromises.push(screenshot ({face: 'FRONT', data: wrapperFront.body.firstChild.outerHTML})
		.then(res => {

			if (res['data']['FRONT']) {
				return {FRONT: res['data']['FRONT']};
			}
			
		}));
	allPromises.push(screenshot ({face: 'BACK', data: wrapperBack.body.firstChild.outerHTML})
		.then(res => {
			if (res['data']['BACK']) {
				return {BACK: res['data']['BACK']};
			}
		
		}));
	
	return Promise.all(allPromises);
};


class Preview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			front: '',
			back: '',
			compiled : false
		};
	}

	 componentDidMount() {
		generateImage(this.props.document)
			.then(data => {
				const value = data.reduce((accumulator, current) => {
					return Object.assign({}, accumulator, current);
				});

				this.setState({
					...this.state, 
					front: (value.FRONT) ? value.FRONT : null,
					back: (value.BACK) ? value.BACK : null,
					compiled: true
				});
			
			});
	 }

	
	  componentDidUpdate() {

		if (this.props.open === false && this.state.compiled === true) {
			this.setState({front: '', back: '', compiled: false});
		}
		
		if (this.state.compiled === false && this.props.open && this.props.document.html) {
			generateImage(this.props.document)
				.then(data => {
					const value = data.reduce((accumulator, current) => {
						return Object.assign({}, accumulator, current);
					});

					this.setState({
						...this.state, 
						front: (value.FRONT) ? value.FRONT : null,
						back: (value.BACK) ? value.BACK : null,
						compiled: true
					});
			
				});

		}


	

	}


	render() {

		const previewFront = (this.state.front !== '')
			? (<img src={this.state.front} width="200px" alt="" />)
			: (<Loader 
				type="ThreeDots"
				color="#00BFFF"
				height="100"	
				width="100"
			/> );

		const previewBack = (this.state.back !== '')
			? (<img src={this.state.back} width="200px" alt="" />)
			: (<Loader 
				type="ThreeDots"
				color="#00BFFF"
				height="100"	
				width="100"
			/> );

		return(
			<div className="overlay opacity_bg open" id="overlay_editeur">
				<div className="box-preview-editeur text-center" style={{display: 'block'}}>
					<div className="row_top_preview pt-80 justify-content-between d-flex">
						<h1 className="title_preview">Aperçu de votre dépliant 3 volets A4</h1>
						<div className="container_btn_top_preview">
							<button onClick={this.props.toggleDrawer} className="btn_medianet btn_default reset_overlay px-20 mr-10"><i className="zmdi zmdi-arrow-left"></i> Retour</button>
							<button className="btn_medianet btn_default px-20 mr-10">Demander un bon à tirer</button>
							<button className="btn_medianet btn_product px-20 mr-10">Terminer et commander</button>
						</div>
					</div>
					<div className="row_bottom_preview d-flex">
						<div className="col-12 col-md-4 col-lg-4 mr-30">
							<div className="row_btn_preview text-left mb-35">
								<span className="caption_bottom_preview">Avant</span>
								<button className="link_bottom_preview" title="Modifier l’avant">Modifier l’avant <i className="zmdi zmdi-arrow-right"></i></button>
							</div>
							<div className="block_preview px-55 py-70">
								<div className="preview_image" id="faceItems">
									{previewFront}
								</div>
							</div>
						</div>
						<div className="col-12 col-md-4 col-lg-4">
							<div className="row_btn_preview text-left mb-35">
								<span className="caption_bottom_preview">Arrière</span>
								<button className="link_bottom_preview" href="#" title="Modifier l’arrière">Modifier l’arrière <i className="zmdi zmdi-arrow-right"></i></button>
							</div>
							<div className="block_preview px-55 py-70">
								<div className="preview_image">
								 {previewBack}
								</div>
							</div>
						</div>
					</div>
					<div className="row_desc_preview text-left mt-30">
						<div className="row">
							<div className="col-12 col-md-12 col-lg-12">
								<h4 className="title_desc_preview mb-15">Veuillez vérifier dans votre fichier les points suivants :</h4>
								<p className="caption_desc_preview">
                            Contenus et orthographe corrects<br />
                            Textes bien lisibles<br />
                            Images claires, non floues<br />
                            Elements suffisamment loin du bord<br />
                            Pas de cadre, pas de surimpression
								</p>
							</div>
						</div>
					</div>
					<div className="besoin_aide pt-15">
						<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/picto_tel.png'} alt="" />
						<p className="caption_besoin_aide">Besoin<span><br />d'aide ?</span></p>
					</div>
				</div>
			</div>

		);
	}
}




const mapStateToProps = state => ({
	document: state.workspaceState.present
});



export default connect(
	mapStateToProps,
	null
)(Preview);
  


