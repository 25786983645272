function generatorSvgArrow (size, rotation) {

	const {width, height } = size;
	
    
	const dataRotation180 = () => [
		'M0 ' + (height / 3).toFixed(2),
		' L'+ (width * 2 / 3).toFixed(2) + ' ' + (height / 3).toFixed(2),
		' L'+ (width * 2 / 3).toFixed(2) + ' 0',
		' L'+ (width-2).toFixed(2)  + ' ' + (height / 2).toFixed(2),
		' L'+ (width * 2 / 3).toFixed(2)  + ' ' + height,
		' L'+ (width * 2 / 3).toFixed(2)  + ' ' + (height * 2 / 3).toFixed(2),
		' L0 ' + (height * 2 / 3).toFixed(2),
	].join(', ');


	const dataRotation0 = () => [
		'M' + (width).toFixed(2) + ' ' + (height / 3).toFixed(2),
		' L'+ (width / 3).toFixed(2) + ' ' + (height / 3).toFixed(2),
		' L'+ (width / 3).toFixed(2) + ' 0',
		' L'+ (2).toFixed(2)  + ' ' + (height / 2).toFixed(2),
		' L'+ (width / 3).toFixed(2)  + ' ' + height,
		' L'+ (width / 3).toFixed(2)  + ' ' + (height * 2 / 3).toFixed(2),
		' L'+ (width).toFixed(2)  + ' ' + (height * 2 / 3).toFixed(2)

	].join(', ');



	const dataRotation90 = () => [
		'M' + (width / 3).toFixed(2) + ' ' + (height).toFixed(2),
		' L'+ (width / 3).toFixed(2) + ' ' + (height / 3).toFixed(2),
		' L0 '+ (height  / 3).toFixed(2),
		' L'+ (width / 2).toFixed(2)  + ' 0',
		' L'+ (width).toFixed(2)  + ' ' + (height / 3).toFixed(2),
		' L'+ (width * 2 / 3).toFixed(2)  + ' ' + (height / 3).toFixed(2),
		' L'+ (width * 2 / 3).toFixed(2)  + ' ' + (height).toFixed(2)

	].join(', ');


	const dataRotation270 = () => [
		'M' + (width / 3).toFixed(2) + ' 0',
		' L'+ (width / 3).toFixed(2) + ' ' + (height * 2 / 3).toFixed(2),
		' L0 '+ (height * 2 / 3).toFixed(2),
		' L'+ (width / 2).toFixed(2)  + ' ' + height,
		' L'+ (width).toFixed(2)  + ' ' + (height * 2 / 3).toFixed(2),
		' L'+ (width * 2 / 3).toFixed(2)  + ' ' + (height * 2/ 3).toFixed(2),
		' L'+ (width * 2 / 3).toFixed(2)  + ' 0'

	].join(', ');
    

	switch (rotation) {
	case 90 : return dataRotation90(); 
	case 180 : return dataRotation180(); 
	case 270 : return dataRotation270(); 
	default: return dataRotation0(); 
	};
        
};


export default generatorSvgArrow;