import React, { Component } from 'react';
import { connect } from 'react-redux';
import { doAddTable } from 'actions/workspace';
import { INITIAL_STATE_TABLE }  from 'initial_states/workspace';


class PanelTableau extends Component {
	constructor(props) {
		super(props);
		this.state = INITIAL_STATE_TABLE;
	}



  

	generateMatrice () {
		const grid = [];
		const params = this.state.params;
		if (params.cols && params.rows) {
     
			const cols = {};
			for (var i = 0; i < params.cols; i++) {
				cols[i] = ' ---- ';
			}
			for (var j = 0; j < params.rows; j++) {
				grid.push(Object.assign({}, cols));
			}
		}
		return Object.assign({}, grid);
	}

	onSubmit() {
		const  state  = this.state;
		if (state) {
			state.params['dataTable'] = this.generateMatrice();
			this.props.doAddTable(state);
			this.setState({ params:{cols: 1, rows : 1 }});
		}
   
	}

	onChangeCols(value) {
		if (value >= 1) {
			this.setState({params:{cols: value, rows: this.state.params.rows}});
		}
	}

	onChangeRows(value) {
		if (value >= 1) {
			this.setState({params:{cols: this.state.params.cols, rows: value}});
		}
	}

	render() {
		return (
			<div>
				<form>
					<div className="container_nbr_col">
						<span className="caption_nbr_col">Nombre de colonnes</span>
						<div className="box_nbr_col d-flex">
							<span className="button-minus-product button_minus_plus" onClick={() => { this.onChangeCols(this.state.params.cols - 1);}}>
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/icon_minus.png'}  alt= "" />
							</span>
							<input type="text" readOnly value={this.state.params.cols}  pattern="[0-9]*" id="quantity_colonne" className="quantity_tab quantity_colonne" />
							<span className="button-plus-product button_minus_plus" onClick={() => { this.onChangeCols(this.state.params.cols + 1);}}>
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/icon_plus.png'}  alt= "" />
							</span>
						</div>
					</div>
					<div className="container_nbr_col">
						<span className="caption_nbr_col">Nombre de rangées</span>
						<div className="box_nbr_col d-flex">
							<span className="button-minus-product button_minus_plus" onClick={() => { this.onChangeRows(this.state.params.rows - 1);}}>
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/icon_minus.png'}  alt= "" />
							</span>
							<input type="text" readOnly  value={this.state.params.rows} name="quantity_rangee" pattern="[0-9]*" id="quantity_rangee" className="quantity_tab quantity_rangee" />
							<span className="button-plus-product button_minus_plus" onClick={() => { this.onChangeRows(this.state.params.rows + 1);}}>
								<img src={process.env.REACT_APP_PUBLIC_URL + 'assets/images/png/icon_plus.png'}  alt= "" />
							</span>
						</div>
					</div>

					<div className="containerbtn_medianet text-center">
						<a className="btn_medianet btn_product mt-40 px-40" href="{}"  onClick={(event) => { event.preventDefault(); this.onSubmit();}} title="Ajouter le tableau">Ajouter le tableau</a>
					</div>
        
				</form> </div>
		);}
}

        

  
const mapDispatchToProps = dispatch => ({
	doAddTable: data => dispatch(doAddTable(data)),
});

export default connect(
	null,
	mapDispatchToProps
)(PanelTableau);
