import { combineReducers } from 'redux';
import undoable, {excludeAction} from 'redux-undo';
import workspaceReducer from './workspace';
import { 
	SET_LIVE_SIZE_POSITION, 
	TOGGLE_SELECTED, 
	UNSELECT_ALLS,
	DISABLE_DRAGGING,
	ENABLE_DRAGGING } from '../constants/actions';
import INITIAL_STATE_WORKSPACE from '../initial_states/workspace';


const rootReducer = combineReducers({
	workspaceState: undoable(workspaceReducer, {
		limit: false,
		filter: excludeAction([
			SET_LIVE_SIZE_POSITION, 
			TOGGLE_SELECTED, 
			UNSELECT_ALLS,
			DISABLE_DRAGGING,
			ENABLE_DRAGGING,
		]),
		initialState:INITIAL_STATE_WORKSPACE,
		initialHistory: { 
			past: [],
			present: INITIAL_STATE_WORKSPACE,
			future: []
		}, 
		debug: true
	}),
});

export default rootReducer;