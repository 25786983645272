import React , { Component } from 'react';
import { connect } from 'react-redux';
import { disableDragging, enableDragging } from 'actions/workspace';
import FormEditCel from 'components/PanelsSettings/PanelTableau/FormEditCel';
import './index.css';




class Tableau extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cellEditable : null
		};

		this.onValidateEditeCel = this.onValidateEditeCel.bind(this);
		this.ref = React.createRef();
	}

	handleClickOutside = (event) => {
		if (this.ref.current && !this.ref.current.contains(event.target)) {
			this.setState({cellEditable : null});
		}
		
	}
	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount () {
		document.removeEventListener('mousedown', this.handleClickOutside); 
	}

	editeCel(coord) {


		this.props.disableDragging({localID : this.props.ele.localID});
		coord.e.preventDefault();
		if (coord.e.currentTarget === coord.e.target) {
			this.setState({cellEditable: coord});
		}
	}


	onValidateEditeCel () {
		this.props.enableDragging({id: this.props.index});
		this.setState({cellEditable: null});
	}

	render() {
		const dataTable = Object.assign({}, this.props.ele.params.dataTable);
		const cell = (row, col) => { 
			return (this.state.cellEditable !== null && this.state.cellEditable.col === col && this.state.cellEditable.row === row)
				? (<FormEditCel index={this.props.index} col={col} row={row} dataTable={dataTable} onValidate={this.onValidateEditeCel} />)
				: (<span className="m-2" onClick={(e) => this.editeCel({e:e, row:row, col:col})}>{dataTable[row][col]}</span>);   
		};

		return (
			<table ref={this.ref} className='table table-bordered' style={{...this.props.ele.size, tableLayout: 'fixed'}}>
				<tbody>
					{
						(Object.keys(dataTable) || []).map(i =>
							<tr key={i}>
								{
									(Object.keys(dataTable[i]) || []).map(j => 
										<td  key={i + '-' + j}> 
											{cell(i, j)}
										</td>)
								}
							</tr>)
					}
				</tbody>
			</table>
		);
	}
    
}


const mapDispatchToProps = dispatch => ({
	disableDragging: data => dispatch(disableDragging(data)),
	enableDragging: data => dispatch(enableDragging(data)),
});
  
export default connect(
	null,
	mapDispatchToProps
)(Tableau);
