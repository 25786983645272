import React , { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import Edit from './components/Edit';
import Color from './components/Color';
import Size from './components/Size';
import InterLine from './components/InterLine';
import Police from './components/Police';
import SpecialCharacters from './components/SpecialCharacters';
import Empty from './components/Empty';

import { 
	doDelete, 
	disableDragging, 
	enableDragging,
	setStyle,
	doDuplicate,
	doEditText } from 'actions/workspace';
import './index.css';

import convert2List from 'utils/convert2List';

class ToolsTexte extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);

		this.state = {
			selectedAction : null,
		};
	}


	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}

	showActionPanel = (event, action) => {
		event.preventDefault(); 
		action = (this.state.selectedAction !== action) ? action : null;
		this.setState({selectedAction : action});
		if (this._componentsAction[action]) {
			this.props.disableDragging({localID : this.props.ele.localID});
		} else {
			this.props.enableDragging({localID : this.props.ele.localID});
		}
	}

	_componentsAction = {
		'Color' : Color, 
		'Edit' : Edit,
		'Size': Size,
		'Police': Police,
		'InterLine': InterLine,
		'SpecialCharacters': SpecialCharacters
	};

	convertList (parentNode = 'ol') {
		const contentHtml = convert2List(this.props.ele.params.text, parentNode);
		const newStateText = Object.assign({},this.props.ele, {...this.props.ele, params:{text: contentHtml}});
    	this.props.doEditText(newStateText);
	}


	positionRotated (x, y, xm, ym, angle) {
		var cos = Math.cos, sin = Math.sin,
			a = angle * Math.PI / 180, 
			xr = (x - xm) * cos(a) - (y - ym) * sin(a)   + xm,
			yr = (x - xm) * sin(a) + (y - ym) * cos(a)   + ym;
		return [xr, yr];
	}

	rotate () {
		const initX = this.props.ele.position.x;
		const initY = this.props.ele.position.y;
		const width = this.props.ele.size.width;
		const height = this.props.ele.size.height;
		const angle = (this.props.ele.rotation + 90 >= 360 ) ? 0 : this.props.ele.rotation + 90;
		const centerX = this.props.ele.position.x + (parseFloat(width) / 2);
		const centerY = this.props.ele.position.y + (parseFloat(height) / 2);
		const newPosition = this.positionRotated(initX, initY, centerX, centerY, 90);

		const flipMode = {
			0 : {writingMode: 'unset', transform :'none'},
			90 : {writingMode: 'vertical-rl', transform :'none'},
			180 :{writingMode: 'unset', transform :'scale(-1, -1)'},
			270 : {writingMode: 'vertical-rl', transform :'scale(-1, -1)'},
		};

		const ResizingMode = {
			0 : {left: true, right :true, top: false, bottom :false},
			90 : {left: false, right :false, top: true, bottom :true},
			180 :{left: true, right :true, top: false, bottom :false},
			270 : {left: false, right :false, top: true, bottom :true},
		};
	
		const size = {width: height, height: width};
		const nexPositionX = newPosition[0] - (parseFloat(height));
		const position = {x: nexPositionX, y: newPosition[1]};
		const style = {...this.props.ele.style,  ...flipMode[angle]};
		const enableResizing = {...this.props.ele.enableResizing, ...ResizingMode[angle]};

		const newStateText = Object.assign({},
			this.props.ele, 
			{...this.props.ele, rotation : angle, size:size, position:position, style: style, enableResizing:enableResizing });

		this.props.doEditText(newStateText);
	}

	render() {

		const settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 4,
			variableWidth: true,
			className: 'actions'
		};
		const action = this.state.selectedAction;
		const ComponentAction = (this._componentsAction[action]) ? this._componentsAction[action] : Empty;
		const fontSize = this.props.ele.style.fontSize.match(/\d+/)[0];
		return (
           
			<div className={(this.state.selectedAction !== null) ? 'edit-widget slide-up-show' : 'edit-widget'}>
				<div className="slip-button"></div>
				<div className="elements-wrapper">
					<div id="option-data">
						<ComponentAction ele={this.props.ele} />
					</div>
					<div className="arrows arrow-left">
						<a id="widget-left" href="{}" onClick={(event) => { event.preventDefault(); this.previous();}}><i className="icon-chevron-right"></i></a>
					</div>
					<div className="actions-wrapper">
						<Slider ref={c => (this.slider = c)} {...settings}>
							
							<button className={(this.state.selectedAction === 'Edit') ? 'action with-text show active' : 'action with-text show'} onClick={(event) => { this.showActionPanel(event, 'Edit'); }}>
								<i className="icon-t-for-text"></i>
							</button>
							<button className={(this.state.selectedAction === 'Color') ? 'action with-text show active' : 'action with-text show'} onClick={(event) => { this.showActionPanel(event, 'Color'); }}>
								<span>Coleur</span>
								<i className="icon-color-wheel"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span><span className="path10"></span><span className="path11"></span><span className="path12"></span><span className="path13"></span><span className="path14"></span><span className="path15"></span><span className="path16"></span></i>
							</button>
							<button className={(this.state.selectedAction === 'Size') ? 'action show active' : 'action show'} onClick={(event) => { this.showActionPanel(event, 'Size'); }}>
								<span className="text-size">{ fontSize }</span>
							</button>
							<button className={(this.state.selectedAction === 'Police') ? 'action    show active' : 'action show'} onClick={(event) => { this.showActionPanel(event, 'Police'); }}>
								<span>Police</span>
							</button>
							<button className={(this.state.selectedAction === 'InterLine') ? 'action show active' : 'action show'} onClick={(event) => { this.showActionPanel(event, 'InterLine'); }}>
								<i className="icon-line-height"></i>
							</button>
							<button className="action" onClick={() => { this.props.setStyle({localID: this.props.ele.localID, style : {textAlign: (this.props.ele.style.textAlign !== 'left') ? 'left' : 'center'}}); }}>
								<i className="icon-align-text-left"></i>
							</button>
							<button className="action" onClick={() => { this.props.setStyle({localID: this.props.ele.localID, style : {textAlign: (this.props.ele.style.textAlign !== 'center') ? 'center' : 'center'}}); }}>
								<i className="icon-align-text-center"></i>
							</button>
							<button className="action" onClick={() => { this.props.setStyle({localID: this.props.ele.localID, style : {textAlign: (this.props.ele.style.textAlign !== 'right') ? 'right' : 'center'}}); }}>
								<i className="icon-align-text-right"></i>
							</button>
							<button className="action" onClick={() => { this.props.setStyle({localID: this.props.ele.localID, style : {textAlign: (this.props.ele.style.textAlign !== 'justify') ? 'justify' : 'center'}}); }}>
								<i className="icon-align-text-justify"></i>
							</button>	


							<button className="action" onClick={() => { this.convertList('ol');}}>
								<i className="icon-list-ol"></i>
							</button>	

							<button className="action" onClick={() => { this.convertList('ul'); }}>
								<i className="icon-list-ul"></i>
							</button>	

							<button className={(this.state.selectedAction === 'SpecialCharacters') ? 'action show active' : 'action show'} onClick={(event) => { this.showActionPanel(event, 'SpecialCharacters'); }}>
								<i className="icon-pi-special-character"></i>
							</button>	


							<button className="action" onClick={() => { this.props.setStyle({localID: this.props.ele.localID, style : {fontWeight: (this.props.ele.style.fontWeight !== 'bold') ? 'bold' : 'normal'}}); }}>
								<i className="icon-font-weight-bold"></i>
							</button>

							<button className="action" onClick={() => { this.props.setStyle({localID: this.props.ele.localID, style : {fontStyle: (this.props.ele.style.fontStyle !== 'italic') ? 'italic' : 'normal'}}); }}>
								<i className="icon-font-style-italic"></i>
							</button>

							<button className="action" onClick={() => { this.props.setStyle({localID: this.props.ele.localID, style : {textDecoration: (this.props.ele.style.textDecoration !== 'underline') ? 'underline' : 'none'}}); }}>
								<i className="icon-text-decoration-underline"></i>
							</button>

							<button className="action" onClick={() => { this.rotate();}}>
								<i className="icon-rotate"></i>
							</button>	

							<button className="action" onClick={() => { }}>
								<i className="icon-reorganize"></i>
							</button>

							<button className="action" onClick={() => { this.props.doDuplicate(this.props.ele); }}>
								<i className="icon-duplicate"></i>
							</button>
							
							<button className="action" onClick={() => {this.props.doDelete({localID: this.props.ele.localID});}}>
								<i className="icon-delete"></i>
							</button>
						</Slider>
					</div>
					<div className="arrows arrow-right">
						<a id="widget-right" href="{}" onClick={(event) => { event.preventDefault(); this.next();}}><i className="icon-chevron-right"></i></a>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	doDelete: data => dispatch(doDelete(data)),
	doDuplicate: data => dispatch(doDuplicate(data)),
	doEditText: data => dispatch(doEditText(data)),
	setStyle: data => dispatch(setStyle(data)),
	disableDragging: data => dispatch(disableDragging(data)),
	enableDragging: data => dispatch(enableDragging(data)),
});

export default connect(
	null,
	mapDispatchToProps
)(ToolsTexte);


