function getCroppedImg(imageObj, crop, mime) {

	var canvas = document.createElement('canvas');
	canvas.width = crop.width;
	canvas.height = crop.height;
	var ctx = canvas.getContext('2d');
	 const image = new Image();
	 image.src = imageObj.src;
	 
	 ctx.drawImage(image,  crop.x,  crop.y, imageObj.width,  imageObj.height, 0, 0, imageObj.width,  imageObj.height);
	 return canvas.toDataURL(); 

}


export default getCroppedImg;