import React , { Component } from 'react';

class Line extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const defaultStyle = {
			'fill':'#ccc2d1',
			'stroke':'#ccc2d1',
			'strokeWidth':'8',
		/*	'fillOpacity':'0.1',
			'strokeOpacity':'0.9'*/
		};

		const style = (Object.keys(this.props.ele.style))
			? {...defaultStyle, ...this.props.ele.style}
			: defaultStyle;
		
		const width = (this.props.ele.size.width !== 'auto')
			? this.props.ele.size.width
			: 100;
		/*const height = (this.props.ele.size.height !== 'auto')
			? this.props.ele.size.height
			: 100;*/
      
		return (
			<svg style={{padding: '0em'}} height={this.props.ele.style.strokeWidth} width={width}>
				<line x1="0" y1={this.props.ele.style.strokeWidth / 2} x2={width} y2={this.props.ele.style.strokeWidth / 2} style={style} />
			</svg>
		);
	}
    
}

export default Line;