import React , { Component } from 'react';
import { connect } from 'react-redux';
import { SketchPicker } from 'react-color';
import { setStyle } from 'actions/workspace';

class Rotate extends Component {

	constructor(props, ctx) {
		super(props, ctx);
		this.state = {};

	}


	handleChangeComplete = (color) => {
		this.props.setStyle({localID: this.props.ele.localID, style : {fill: color.hex}});
	}
	render() {
		const color = (this.props.ele.style.color) ? this.props.ele.style.color : '#fff';
    	return (
			<SketchPicker 
				color={ color } 
				width="100%"
			 onChangeComplete={ this.handleChangeComplete }
			 />
    	);}
}



const mapDispatchToProps = dispatch => ({
	setStyle: data => dispatch(setStyle(data)),
});
  
export default connect(
	null,
	mapDispatchToProps
)(Rotate);