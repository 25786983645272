import React , { Component } from 'react';


  
class Triangle extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<svg height="210" width="400">
				<path d="M150 0 L75 200 L225 200 Z" />
			</svg>
		);
	}
    
}

export default Triangle;