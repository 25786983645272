import React from 'react';
import { getAllDocuments } from 'api/workspace';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import INITIAL_STATE_WORKSPACE from 'initial_states/workspace';
import { doSetState } from 'actions/workspace';
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { deleteDocument } from 'api/workspace';
import './Documents.css';



class Documents extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items:[]
		};
	}

	_deleteDocument (id) {
		deleteDocument(id)
			.then(res => {
				const newListItems = this.state.items.filter(item => (item.id !== id));
				NotificationManager.success('La document a été supprimée avec succès', 'Suppression');
				this.setState({items: newListItems});
			});
	}

	componentDidMount(){
		this.props.doSetState(INITIAL_STATE_WORKSPACE);

		getAllDocuments()
			.then(res => {
				this.setState({items: res.data.data.rows});
		  });
	  }
    
	render() {
		return(


			<div id="overlay_editeur">
				<div className="d-flex container_finish_save aligns-top-items">
   
					<div className="box-preview-editeur text-center" style={{display:'block'}} >
						<div className="row_top_preview pt-80 justify-content-between d-flex">
							<h1 className="title_preview">Tous les documents</h1>
							<div className="container_btn_top_preview">
								<Link className="btn_medianet btn_product px-20 mr-10" to={'/editor/'}>Nouvelle carte</Link>
							</div>
						</div>
						<div className="row_bottom_preview d-flex  flex-wrap flex-doc">



							{
								(this.state.items || []).map(item => 
									<div className="col-12 col-md-4 col-lg-4 recover_padd" key={item.id}>
										<div className="row_btn_preview text-left mb-35">
											<span className="caption_bottom_preview">{item.title}</span>
											<Link className="marge_link_edit link_bottom_preview" to={`/editor/${item.id}`}>Modifier</Link>
											<button  onClick={() => this._deleteDocument (item.id)} className="link_bottom_preview">Supprimer</button>
										</div>
										
										
										<div className="block_preview px-55 py-70">
											<div className="preview_image">
												<img src="assets/images/jpg/preview-1.jpg" alt="" />
											</div>
										</div>
									</div>
								)
							}










						
						</div>
						<div className="row_desc_preview text-left mt-30">
							<div className="row">
								<div className="col-12 col-md-12 col-lg-12">
									<h4 className="title_desc_preview mb-15">Veuillez vérifier dans votre fichier les points suivants :</h4>
									<p className="caption_desc_preview">
                            Contenus et orthographe corrects<br />
                            Textes bien lisibles<br />
                            Images claires, non floues<br />
                            Elements suffisamment loin du bord<br />
                            Pas de cadre, pas de surimpression
									</p>
								</div>
							</div>
						</div>
						 
					</div>

				
				</div>
			
			</div>


		

							
								
						
		
		);
	}
}


const mapDispatchToProps = dispatch => ({
	doSetState: data => dispatch(doSetState(data)),
});


export default connect(
	null,
	mapDispatchToProps
)(Documents);
