import React , { Component } from 'react';


  
class Square extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {

		const defaultStyle = {
			'fill':'#ccc2d1',
			'stroke':'#ccc2d1',
			'strokeWidth':'1',
		/*	'fillOpacity':'0.1',
			'strokeOpacity':'0.9'*/
		};

		const style = (this.props.ele.style)
			? Object.assign({}, defaultStyle, this.props.ele.style)
			: defaultStyle;

		return (
			<svg 
				width={this.props.ele.size.width !== 'auto' ? this.props.ele.size.width : '100'}
				height={this.props.ele.size.height !== 'auto' ? this.props.ele.size.height : '100'}>
				<rect x="0" y="0" 
					width={this.props.ele.size.width !== 'auto' ? this.props.ele.size.width : '100'} 
					height={this.props.ele.size.height !== 'auto' ? this.props.ele.size.height : '100'}
					style={style} />
			</svg>
		);
	}
    
}

export default Square;