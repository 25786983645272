import React , { Component } from 'react';
import getPathStar from 'utils/getPathStar';
import './index.css';

class Star extends Component {
	constructor(props) {
		super(props);
		this.state = {};

	
	}

	render() {


		const defaultStyle = {
			'fill':'#ccc2d1',
			'stroke':'#ccc2d1',
			'strokeWidth':'1',
		/*	'fillOpacity':'0.1',
			'strokeOpacity':'0.9'*/
		};

		const style = (this.props.ele.style)
			? Object.assign({}, defaultStyle, this.props.ele.style)
			: defaultStyle;


		const params = this.props.ele.params;
		const paramsSize = (this.props.ele.size) ? this.props.ele.size : {width: 100, height: 100};
		let size = (paramsSize) ? paramsSize.width : 100;
		const teeths = (params.teeth) ? params.teeth : 8;
		let d1 = (params.d1) ? params.d1 : 1;
		const d2 = (params.d2) ? params.d2 : .6875;
		let d3 = (params.d3) ? params.d3 : 0;
		const fill = (params.fill) ? params.fill : '#ccc2d1';
		
		// d1 -= style.strokeWidth;
		// d3 -= style.strokeWidth;

		const pathData = getPathStar(size, teeths, d1, d2, d3, params.splay);
		const viewBox = [0, 0, size, size].join(' ');
		
		// size +=  style.strokeWidth;

		return (
			

			<svg
				xmlns="http://www.w3.org/svg/2000"
				viewBox={viewBox}
				width={size}
				height={size}
				fill={fill}>
				<path d={pathData} style={style} />
			</svg>
            
		);
	}
    
}


Star.defaultProps = {
	ele : {
		params : {
			size: 100,
			d1: 1,
			d2: .6875,
			d3: 0,
			teeth: 8,
			splay: .375,
			fill: '#ccc2d1',
			showGuidelines: false // For demonstration
		}
	}
};

export default Star;