import React , { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { doDelete, doDuplicate } from 'actions/workspace';

class ToolsTableau extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}


	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}

	render() {

		const settings = {
			dots: false,
			infinite: false,
			speed: 500,
			nav:false,
			slidesToShow: 4,
			slidesToScroll: 4,
			variableWidth: true,
			className: 'actions'
		};
     
		return (
         
			<div className="edit-widget">
				<div className="slip-button"></div>
				<div className="elements-wrapper">

					<div id="option-data">

					</div>
					<div className="arrows arrow-left">
						<a id="widget-left" href="{}" onClick={(event) => { event.preventDefault(); this.previous()}}><i className="icon-chevron-right"></i></a>
					</div>
					<div className="actions-wrapper">

						<Slider ref={c => (this.slider = c)} {...settings}>


							<button className="action with-text" href="{}" data-option-data="fill-in-color">
								<span>Remplissage</span>
								<i className="icon-color-wheel"></i>
							</button>

							<button className="action with-text" href="{}">
								<span>Modifier</span> 
							</button>



							<button className="action" href="{}">
								<i className="icon-align-text-left"></i>
							</button>



							<button className="action" href="{}">
								<i className="icon-align-text-center"></i>
							</button>



							<button className="action" href="{}">
								<i className="icon-align-text-right"></i>
							</button>


							<button className="action" href="{}">
								<i className="icon-align-text-justify"></i>
							</button>


							<button className="action" href="{}">
								<i className="icon-border"></i>
							</button>
							<button className="action" onClick={() => { this.props.doDuplicate(this.props.ele); }}>
								<i className="icon-duplicate"></i>
							</button>
							<button className="action" href="{}" onClick={(event) => { event.preventDefault(); this.props.doDelete(this.props.ele);}}>
								<i className="icon-delete"></i>
							</button>

						</Slider>

					</div>

					<div className="arrows arrow-right">
						<a id="widget-right" href="{}" onClick={(event) => { event.preventDefault(); this.next(); }}><i className="icon-chevron-right"></i></a>
					</div>


				</div>
			</div>
		)
	}
}



const mapDispatchToProps = dispatch => ({
	doDelete: data => dispatch(doDelete(data)),
	doDuplicate: data => dispatch(doDuplicate(data)),
});

export default connect(
	null,
	mapDispatchToProps
)(ToolsTableau);
  