import React from 'react';
import WorkSpace from './WorkSpace';
import Face from './Face';
import TopRightColEditeur from './TopRightColEditeur';





class RightColEdituer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id:null,
			open: false,
			position: 'top',
			noOverlay: false,
			zoom : 1
		};
	}

	handelChangeZoom (value) {
		this.setState({zoom : value});
	}

	render() {
		return(

			<div className="right_col_editeur">
				<div className="row_top_editeur justify-content-between d-flex">
					<p className="text_top_editeur pl-20">
                Editeur graphique - <span>créer votre propre graphisme</span>
					</p>
					<div className="bloc_login_editeur d-flex">
						<a href="#?" className="connexion_header" title="Connexion">
							<span className="picto_connexion"></span>
                    Connexion
							<span className="account_header">Mon compte</span>
						</a>
						<button className="btn_editeur"> Quitter</button>
					</div>
				</div>
				<TopRightColEditeur 
					doSaveDocument={this.props.doSaveDocument} 
					doRenderPdf={this.props.doRenderPdf} 
					showPreview={this.props.showPreview} 
					handelChangeZoom={(value) => this.handelChangeZoom(value)} 
					zoom = {this.state.zoom}
				/>
				<Face />
				<div className="container_bloc_editeur">    
					<WorkSpace zoom={this.state.zoom} />     
				</div>
			</div>);
	}

}

export default RightColEdituer;