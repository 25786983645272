import React, {Component} from 'react';
import { connect } from 'react-redux';
import { doAddImage } from 'actions/workspace';
import { INITIAL_STATE_IMAGE }  from 'initial_states/workspace';
import DropZoneFile from './DropZoneFile';
import './index.css';  

class PanelImage extends Component {
	constructor(props) {
		super(props);
		this.state = INITIAL_STATE_IMAGE;
		this.updateState = this.updateState.bind(this);
	}
  
	_doAddImage() {
		let imagePreviewUrl = this.state.params.src;
		if (imagePreviewUrl) {
			this.props.doAddImage(this.state);
		}
      
	}

	updateState = (state) => {
		this.setState(state);
	}
  
	_handleImageChange(e) {
		e.preventDefault();
  
		let reader = new FileReader();
		let file = e.target.files[0];
		const MIMETYPE = file.type;
  
		reader.onloadend = () => {
			var image = new Image();
			image.src = reader.result;
			image.onload =  () => {

				this.setState({
					params: {
						...this.state.params,
						srcRef: reader.result,
						src: reader.result,
						mime: MIMETYPE

					},
					size: {
						...this.state.size,
						width: image.width,
						height: image.height
					}
				});
			};
		
		};

		reader.readAsDataURL(file);
	}


	_handleDrop(files) {
		let reader = new FileReader();
		let file = files[0];
		const MIMETYPE = file.type;
		reader.onloadend = () => {

			var image = new Image();
			image.src = reader.result;
			image.onload =  () => {

				this.setState({
					params: {
						...this.state.params,
						srcRef: reader.result,
						src: reader.result,
						mime: MIMETYPE

					},
					size: {
						...this.state.size,
						width: image.width,
						height: image.height
					}
				});
			};

		};
		reader.readAsDataURL(file);
	}
  
	render() {
		let imagePreviewUrl = this.state.params.src;
		let imagePreview = null;
		if (imagePreviewUrl) {
			imagePreview = 
        
       (<div className="img_download_editeur container_box_myimages" onClick={() => {this._doAddImage();}}>
       	<img src={imagePreviewUrl} alt="" />
       </div>);
       
		} 
  
		return (


			<div>

				<div className="box-inputfile mb-35" ref = {this.dropRef}>
					<div className="img_upload_progress">
						<div className="value text-color-22">49%</div>
						<div className="progress">
							<div className="progress-bar bg-color-22" role="progressbar" style={{width: '49%'}} aria-valuenow={49 + '%'} aria-valuemin="0" aria-valuemax="100"></div>
						</div>
					</div>
					<input type="file" name="file-5[]" id="file-5" className="inputfile" multiple  onChange={(e)=>this._handleImageChange(e)} />
					<label htmlFor="file-5"> <img src="assets/images/png/picto_download.png"  alt= "" /></label>
					<DropZoneFile handleDrop={(file) => this._handleDrop(file) } />
				</div>



				<div className="container_form_editeur d-flex">
					<div className="box_form_editeur box_form_image_recent d-flex">
						<span className="picto_form_editeur mb-10 picto_imagerecent"> </span>
						<span className="caption_form_editeur">Récentes</span>
					</div>

					<div className="box_form_editeur box_form_myimages d-flex">
						<span className="picto_form_editeur mb-10 picto_myimages"> </span>
						<span className="caption_form_editeur">Mes images</span>
					</div>
					<div className="box_form_editeur box_form_image_biblio d-flex">
						<span className="picto_form_editeur mb-10 picto_imagebiblio"> </span>
						<span className="caption_form_editeur">Bibliothèque</span>
					</div>
				</div>
				<div className="box_slide_forms container_images_recent" style={{display: 'block'}}>
					<div className="d-flex box_slide_graphs">
						<h5 className="title_box_graph">Images récentes ajoutées</h5>
						{imagePreview}
					</div>
				</div>
				<div className="box_slide_forms container_mesimages">
					<div className="d-flex box_slide_graphs">
						<h5 className="title_box_graph">Mes Images</h5>
						<div className="img_download_editeur container_box_myimages">
							<img src="assets/images/jpg/voiture-1.jpg" alt="" />
						</div>
						<div className="img_download_editeur container_box_myimages">
							<img src="assets/images/png/voiture-2.png"  alt= "" />
						</div>
						<div className="img_download_editeur container_box_myimages">
							<img src="assets/images/jpg/voiture-3.jpg" alt="" />
						</div>
						<div className="img_download_editeur container_box_myimages">
							<img src="assets/images/jpg/voiture-3.jpg" alt="" />
						</div>
						<div className="img_download_editeur container_box_myimages">
							<img src="assets/images/jpg/voiture-1.jpg" alt="" />
						</div>
						<div className="img_download_editeur container_box_myimages">
							<img src="assets/images/png/voiture-2.png"  alt= "" />
						</div>
					</div>
				</div>
				<div className="box_slide_forms  container_images_bibilo">
					<div className="box_slide_graphs">
						<h5 className="title_box_graph">Bibliothèque d’images</h5>
						<div className="form_search_biblio">
							<form className="form_search" method="post" action="" noValidate="novalidate">
								<div className="form-group">
									<input type="text" name="recherche" className="form-control" placeholder="Rechercher une image" autoComplete="off" />
									<input type="submit" className="search-submit" />
								</div>
							</form>
						</div>
						<div className="scroll-wrapper d-flex box_slide_graphs scrollbar-inner" style={{position: 'relative'}}><div className="d-flex box_slide_graphs scrollbar-inner scroll-content" style={{height: '365px',  marginBottom: '0px', marginRight: '0px', maxHeight: 'none'}}>
							<div className="container_box_biblio">
								<img src="assets/images/jpg/img-1.jpg" alt="" />
							</div>
							<div className="container_box_biblio">
								<img src="assets/images/jpg/img-2.jpg" alt="" />
							</div>
							<div className="container_box_biblio">
								<img src="assets/images/jpg/img-3.jpg" alt="" />
							</div>
							<div className="container_box_biblio">
								<img src="assets/images/jpg/img-4.jpg" alt="" />
							</div>
							<div className="container_box_biblio">
								<img src="assets/images/jpg/img-5.jpg" alt="" />
							</div>
							<div className="container_box_biblio">
								<img src="assets/images/jpg/img-6.jpg" alt="" />
							</div>
							<div className="container_box_biblio">
								<img src="assets/images/jpg/img-7.jpg" alt="" />
							</div>
							<div className="container_box_biblio">
								<img src="assets/images/jpg/img-8.jpg" alt="" />
							</div>
							<div className="container_box_biblio">
								<img src="assets/images/jpg/img-2.jpg" alt="" />
							</div>
							<div className="container_box_biblio">
								<img src="assets/images/jpg/img-3.jpg" alt="" />
							</div>
							<div className="container_box_biblio">
								<img src="assets/images/jpg/img-4.jpg" alt="" />
							</div>
							<div className="container_box_biblio">
								<img src="assets/images/jpg/img-5.jpg" alt="" />
							</div>
						</div><div className="scroll-element scroll-x"><div className="scroll-element_outer"><div className="scroll-element_size"></div><div className="scroll-element_track"></div><div className="scroll-bar"></div></div></div><div className="scroll-element scroll-y"><div className="scroll-element_outer"><div className="scroll-element_size"></div><div className="scroll-element_track"></div><div className="scroll-bar"></div></div></div></div>
					</div>
				</div>

			</div>
        
		);
	}
}
    

const mapDispatchToProps = dispatch => ({
	doAddImage: data => dispatch(doAddImage(data)),
});
  
export default connect(
	null,
	mapDispatchToProps
)(PanelImage);
