import React , { Component } from 'react';
import { connect } from 'react-redux';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { 
	doAddSquare,
	doAddCircle,
	doAddStar,
	doAddLine,
	doAddTriangle } from 'actions/workspace';
import Star from '../../Models/Forme/Star';
import { 
	INITIAL_STATE_LINE, 
	INITIAL_STATE_SQUARE, 
	INITIAL_STATE_CIRCLE,
	INITIAL_STATE_STAR 
}  from 'initial_states/workspace';  
import { PanelTableau } from 'components/PanelsSettings';
import Arrow from './components/Arrow';

class PanelForme extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedModel : null,
			starParams : {
				teeth : 5,
				d2: 0.4687,
				d3: 0,
				splay:  1,
			}
		};
	}

	
	doAddCircle (data) {
		this.setState({selectedModel : null});
		this.props.doAddCircle(data);
	}

	doAddSquare (data) {
		this.setState({selectedModel : null});
		this.props.doAddSquare(data);
	}

	doAddTriangle (data) {
		this.setState({selectedModel : null});
		this.props.doAddTriangle(data);
	}

	doAddStar (event, data) {
		event.preventDefault();
		/*const {teeth, d2, d3, splay} = this.state.starParams;
		const params = {...this.state.starParams, dataPath: getPathStar(data.size.width, teeth, 1, d2, d3, splay)};*/
		const _data = Object.assign({}, data, {...data, params:this.state.starParams});
		this.props.doAddStar(_data);
	}

	doAddLine (data) {
		this.setState({selectedModel : null});
		this.props.doAddLine(data);
	}

	render() {
		return (
			<div>
				<div className="container_form_editeur d-flex">
					<div className="box_form_editeur box_form_editeur_rectangle d-flex" onClick={() => this.doAddSquare(INITIAL_STATE_SQUARE)}>
						<span className="picto_form_editeur mb-10 picto_rectangle"> </span>
						<span className="caption_form_editeur">Rectangle</span>
					</div>
					<div className="box_form_editeur box_form_editeur_cercle d-flex" onClick={() => this.doAddCircle(INITIAL_STATE_CIRCLE)}>
						<span className="picto_form_editeur mb-10 picto_cercle"> </span>
						<span className="caption_form_editeur">Cercle</span>
					</div>
					<div className="box_form_editeur box_form_editeur_ligne d-flex" onClick={() => this.doAddLine(INITIAL_STATE_LINE)}>
						<span className="picto_form_editeur mb-10 picto_ligne"> </span>
						<span className="caption_form_editeur">Ligne</span>
					</div>
					<div className="box_form_editeur box_form_editeur_etoile d-flex"  onClick={() => this.setState({selectedModel : 'ETOILE'})}>
						<span className="picto_form_editeur mb-10 picto_etoile"> </span>
						<span className="caption_form_editeur">Etoile</span>
					</div>
					<div className="box_form_editeur box_form_editeur_fleche d-flex" onClick={() => this.setState({selectedModel : 'FLECHE'})}>
						<span className="picto_form_editeur mb-10 picto_fleche"> </span>
						<span className="caption_form_editeur">Flèche</span>
					</div>
					<div className="box_form_editeur box_form_editeur_tableau d-flex"  onClick={() => this.setState({selectedModel : 'TABLEAU'})}>
						<span className="picto_form_editeur mb-10 picto_tableau"> </span>
						<span className="caption_form_editeur">Tableau</span>
					</div>

				</div>

				<div className="box_slide_forms container_etoile_editeur starRange" style={{display: (this.state.selectedModel === 'ETOILE') ?'block' : 'none'}}>
					<h4 className="title_box_form">Créez votre étoile</h4>
					<div className="container_nbr_col">
						<span className="caption_nbr_col">Nombre de pointes</span>
						<div className="box_nbr_col d-flex">
							<input className="inputstate" type="text" readOnly value={this.state.starParams.teeth}/>
							<InputRange
								maxValue={24}
								minValue={3}
								value={this.state.starParams.teeth}
								onChange={value => this.setState({ starParams: {...this.state.starParams, teeth: value} })} />
						</div>
					</div>
					<div className="container_nbr_col">
						<span className="caption_nbr_col">Hauteur des pointes</span>
						<div className="box_nbr_col d-flex ">
							<input className="inputstate" type="text" readOnly value={(this.state.starParams.d2 * 10).toFixed(0)}/>	
					
							<InputRange
								maxValue={1}
								step={0.03125}
								minValue={0.25} 
								value={this.state.starParams.d2}
								onChange={value => this.setState({ starParams: {...this.state.starParams, d2: value} })} />
						</div>
					</div>
					<div className="container_nbr_col">
						<span className="caption_nbr_col">Évaser</span>
						<div className="box_nbr_col d-flex">

							<input className="inputstate" type="text" readOnly value={(this.state.starParams.splay* 10).toFixed(0)}/>
							<InputRange
								maxValue={1}
								step={0.03125}
								minValue={0}
								value={this.state.starParams.splay}
								onChange={value => this.setState({ starParams: {...this.state.starParams, splay: value} })} />
						</div>
					</div>
					<div className="containerbtn_etoile_large mt-35 text-center">
						<Star ele={{params: this.state.starParams}} />
					</div>
					<div className="containerbtn_medianet text-center">
						<a className="btn_medianet btn_product mt-35 px-40" href="{}" onClick={(event) => this.doAddStar(event, INITIAL_STATE_STAR)} title="Ajouter l’étoile">Ajouter l’étoile</a>
					</div>
				</div>
				<div className="box_slide_forms container_fleche" style={{display: (this.state.selectedModel === 'FLECHE') ?'block' : 'none'}}>
					<h4 className="title_box_form">Créez votre flèche</h4>
					<Arrow />
				</div>
				<div className="box_slide_forms container_tableaux_editeur" style={{display: (this.state.selectedModel === 'TABLEAU') ?'block' : 'none'}}>
					<h4 className="title_box_form">Créez votre tableau</h4>
					<PanelTableau />
				</div>
                
			</div>

            
		);
	}
}
    

const mapDispatchToProps = dispatch => ({
	doAddSquare: data => dispatch(doAddSquare(data)),
	doAddCircle: data => dispatch(doAddCircle(data)),
	doAddStar: data => dispatch(doAddStar(data)),
	doAddLine: data => dispatch(doAddLine(data)),
	doAddTriangle: data => dispatch(doAddTriangle(data)),
});
  
export default connect(
	null,
	mapDispatchToProps
)(PanelForme);
