import React, { Component } from 'react';

class Text extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.ref = React.createRef();
	}


	
	componentDidUpdate() {

		let currentHeight = this.ref.current.getBoundingClientRect().height;
		let childrensHeight = 0;
		/*this.ref.current.children.map(child => {
			childrensHeight += child.getBoundingClientRect().height;
		});*/
		for (let child of this.ref.current.children) {
			childrensHeight += child.getBoundingClientRect().height;
		}

		currentHeight  = (childrensHeight > 0)
			? childrensHeight
			: currentHeight;

		// const currentParentHeight = this.props.parentRef.current.props.size.height;

		if (this.props.ele.size.height !== currentHeight) {
			this.props.setSize(this.props.ele.localID, {
				width :  this.props.ele.size.width ,
				height :  currentHeight 
			}); 
		}
		
	
	}

	_setSize () {
		this.props.setSize(this.props.ele.localID, {
			width :  'auto' ,
			height :  'auto' 
		});
		if (this.ref.current !== null && (this.props.ele.size.width !== this.ref.current.offsetWidth || this.props.ele.size.height !== this.ref.current.offsetHeight)) {
			this.props.setSize(this.props.ele.localID, {
				width :  this.ref.current.offsetWidth ,
				height :  this.ref.current.offsetHeight + 30
			});
		}
		
	}

	render() {

		// this._setSize();
		const width = (isNaN(this.props.ele.size.width)) ? 'auto' : (this.props.ele.size.width) + 'px';
		// const height = (isNaN(this.props.ele.size.height)) ? 'auto' : (this.props.ele.size.height) + 'px';
		const style = {...this.props.ele.style, 
			position:'relative',
			width:width, 
		 	minHeight:'100%',
			top:'0px', 
			left:'0px',
			margin: '-1px 0px',
			border: 'none',
			boxSizing:'border-box'
		};
		const content = ('<div>' + this.props.ele.params.text + '</div>');
		return (<div style={style} ref={this.ref} dangerouslySetInnerHTML={{__html: content}}></div>);
	}

}


export default Text;