import React , { Component,  } from 'react';
import { connect } from 'react-redux';
import { doAddArrow } from 'actions/workspace';
import { INITIAL_STATE_ARROW }  from 'initial_states/workspace';  


class Arrow extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	
	
	doAddArrow (rotation) {

		const size = Object.assign({}, INITIAL_STATE_ARROW.size);
		if (rotation === 90 || rotation === 270) {
			const p = size.height;
			size.height = size.width;
			size.width = p;
		}

		const state = Object.assign({}, INITIAL_STATE_ARROW, {rotation : rotation, size: size});
		this.props.doAddArrow(state);
	}

	

	render() {

		return (
			
			<div className="d-flex box_fleches mt-35">
				<div className="box_form_fleche box_form_fleche_left d-flex" onClick={() => this.doAddArrow(0) }>
					<span className="picto_form_fleche mb-10 picto_fleche_left"> </span>
					<span className="caption_form_fleche">Gauche</span>
				</div>
				<div className="box_form_fleche box_form_fleche_top d-flex" onClick={() => this.doAddArrow(90) }>
					<span className="picto_form_fleche mb-10 picto_fleche_top"> </span>
					<span className="caption_form_fleche">Haut</span>
				</div>
				<div className="box_form_fleche box_form_fleche_bottom d-flex" onClick={() => this.doAddArrow(270) }>
					<span className="picto_form_fleche mb-10 picto_fleche_bottom"> </span>
					<span className="caption_form_fleche">Bas</span>
				</div>
				<div className="box_form_fleche box_form_fleche_right d-flex" onClick={() => this.doAddArrow(180) } >
					<span className="picto_form_fleche mb-10 picto_fleche_right"> </span>
					<span className="caption_form_fleche">Droite</span>
				</div>
			</div>
            
		);
	}
}
 

const mapDispatchToProps = dispatch => ({
	doAddArrow: data => dispatch(doAddArrow(data)),
});
  
export default connect(
	null,
	mapDispatchToProps
)(Arrow);
