import axios from 'axios';
const URL_API = process.env.REACT_APP_API_URL;


const END_POINTS_GENERATOR_PDF =  URL_API;
const END_POINTS_SAVE_DOCUMENT =  URL_API + 'documents/';
const END_POINTS_CONVERT2IMAGE_DOCUMENT =  URL_API + 'dom2image/';
const END_POINTS_CONVERT_HTML2IMAGE_DOCUMENT =  URL_API + 'html2image/';
const END_POINTS_CONVERT_JSHTML2IMAGE_DOCUMENT =  URL_API + 'jsHtml2pdf/';
const END_POINTS_SCREENSHOT_DOCUMENT =  URL_API + 'screenshot/';
const END_POINTS_PDFGENERATOR_DOCUMENT =  URL_API + 'pdfgenerator/';


const generatePDF = (data) => {
	return axios.post(END_POINTS_GENERATOR_PDF,  data, {
		headers: {
			'Accept': 'application/pdf',
		},
		responseType: 'blob'
	} );     
};


const saveDocument = (data) => {
	return (data.id)
		? axios.put(END_POINTS_SAVE_DOCUMENT,  data)
		: axios.post(END_POINTS_SAVE_DOCUMENT,  data); 
};


const getAllDocuments = () => {
	return axios.get(END_POINTS_SAVE_DOCUMENT);     
};


const getDocument = (id) => {
	return axios.get(END_POINTS_SAVE_DOCUMENT + id);     
};

const deleteDocument = (id) => {
	return axios.delete(END_POINTS_SAVE_DOCUMENT + id);     
};


const convertDom2Image = (data) => {
	return axios.post(END_POINTS_CONVERT2IMAGE_DOCUMENT,  data); 
};

const convertHtml2image = (data) => {
	return axios.post(END_POINTS_CONVERT_HTML2IMAGE_DOCUMENT,  data); 
};

const jsHtml2pdf = (data) => {
	return axios.post(END_POINTS_CONVERT_JSHTML2IMAGE_DOCUMENT,  data); 
};


const screenshot = (data) => {
	return axios.post(END_POINTS_SCREENSHOT_DOCUMENT,  data); 
};

const pdfGenerator = (data) => {
	return axios.post(END_POINTS_PDFGENERATOR_DOCUMENT,  data); 
};




export {
	generatePDF,
	saveDocument,
	getAllDocuments,
	getDocument,
	deleteDocument,
	convertDom2Image,
	convertHtml2image,
	jsHtml2pdf,
	screenshot,
	pdfGenerator
};