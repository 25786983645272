import {FRONT} from '../constants/faces';

const INITIAL_STATE_WORKSPACE = {
	activeFace : FRONT,
	zoom : 1,
	width : 1000,
	height: 600,
	id : null,
	title : '',
	user_id: null,
	items: [],
	html: {},
	canvas : {}
};

const INITIAL_STATE_POSITION = {
	x: 50,
	y: 50
};
const INITIAL_STATE_SIZE = {
	width: 'auto',
	height: 'auto'
};

const INITIAL_STATE_SQUARE = {
	localID: null,
	type: 'SQUARE',
	face: FRONT,
	params: null,
	position: INITIAL_STATE_POSITION,
	size: {
		width: 100,
		height: 100
	},
	style : {
		fill:'#ccc2d1',
		stroke:'#ccc2d1',
		strokeWidth:1
	},
	selected: false,
	disableDragging: false,
	enableResizing : {
		bottom: true,
		bottomLeft: true,
		bottomRight: true,
		left: true,
		right: true,
		top: true,
		topLeft: true,
		topRight:true
	},
	lockAspectRatio : false,
};
const INITIAL_STATE_CIRCLE = {
	localID: null,
	type: 'CIRCLE',
	face: FRONT,
	params: null,
	position: INITIAL_STATE_POSITION,
	size: {
		width: 100,
		height: 100
	},
	style : {
		fill:'#ccc2d1',
		stroke:'#ccc2d1',
		strokeWidth:1
	},
	selected: false,
	enableResizing : {
		bottom: true,
		bottomLeft: true,
		bottomRight: true,
		left: true,
		right: true,
		top: true,
		topLeft: true,
		topRight:true
	},
	lockAspectRatio : false,
};

const INITIAL_STATE_ARROW = {
	localID: null,
	type: 'ARROW',
	face: FRONT,
	params: null,
	position: INITIAL_STATE_POSITION,
	size: {
		width: 80,
		height: 26
	},
	style : {
		fill:'#ccc2d1',
		stroke:'#ccc2d1',
		strokeWidth:0
	},
	selected: false,
	enableResizing : {
		bottom: true,
		bottomLeft: true,
		bottomRight: true,
		left: true,
		right: true,
		top: true,
		topLeft: true,
		topRight:true
	},
	rotation: 0,
	lockAspectRatio : false,
};

const INITIAL_STATE_STAR = {
	localID: null,
	type: 'STAR',
	face: FRONT,
	params: null,
	position: INITIAL_STATE_POSITION,
	size: {
		width: 100,
		height: 100
	},
	style : {},
	selected: false,
	enableResizing : {
		bottom: false,
		bottomLeft: true,
		bottomRight: true,
		left: false,
		right: false,
		top: false,
		topLeft: true,
		topRight:true
	},
	lockAspectRatio : false,
};
const INITIAL_STATE_LINE = {
	localID: null,
	type: 'LINE',
	face: FRONT,
	params: null,
	position: INITIAL_STATE_POSITION,
	size: {
		width: 100,
		height: 7
	},
	style : {
		fill:'#ccc2d1',
		stroke:'#ccc2d1',
		strokeWidth:7
	},
	selected: false,
	enableResizing : {
		bottom: false,
		bottomLeft: false,
		bottomRight: false,
		left: true,
		right: true,
		top: false,
		topLeft: false,
		topRight:false
	},
	lockAspectRatio : false,
};
const INITIAL_STATE_TRIANGLE = {
	localID: null,
	type: 'TRIANGLE',
	face: FRONT,
	params: null,
	position: INITIAL_STATE_POSITION,
	size: INITIAL_STATE_SIZE,
	style : {},
	selected: false,
	enableResizing : {
		bottom: true,
		bottomLeft: false,
		bottomRight: false,
		left: true,
		right: true,
		top: true,
		topLeft: false,
		topRight:false
	},
	lockAspectRatio : false,
};
const INITIAL_STATE_TABLE = {
	localID: null,
	type: 'TABLE',
	face: FRONT,
	params: {
		cols: 3,
		rows: 3
	},
	position: INITIAL_STATE_POSITION,
	size: INITIAL_STATE_SIZE,
	style : {},
	selected: false,
	enableResizing : {
		bottom: true,
		bottomLeft: false,
		bottomRight: false,
		left: true,
		right: true,
		top: true,
		topLeft: false,
		topRight:false
	},
	lockAspectRatio : false,
};
const INITIAL_STATE_IMAGE = {
	localID: null,
	type: 'IMAGE',
	face: FRONT,
	params: {
		srcRef: '',
		src: '',
		filter :  {
			saturate : 0,
			desaturate : 0,
			lighten : 0,
			brighten: 0,
			hue : 0,
		},
		filterCss :  {
			saturate : 1,
			desaturate : 0,
			lighten : 0,
			brighten: 100,
			hue : 0,
		}
	},
	position: INITIAL_STATE_POSITION,
	size: INITIAL_STATE_SIZE,
	style : {},
	selected: false,
	enableResizing : {
		bottom: false,
		bottomLeft: true,
		bottomRight: true,
		left: false,
		right: false,
		top: false,
		topLeft: true,
		topRight:true
	},
	crop:{},
	rotation: 0,
	lockAspectRatio : true,
};
const INITIAL_STATE_FORM = {
	localID: null,
	type: 'FORME',
	face: FRONT,
	params: null,
	position: INITIAL_STATE_POSITION,
	size: INITIAL_STATE_SIZE,
	style : {},
	selected: false
};
const INITIAL_STATE_TEXT = {
	localID: null,
	type: 'TEXT',
	face: FRONT,
	placeholder: 'Saisissez votre texte',
	params:{text:''},
	position: INITIAL_STATE_POSITION,
	size: {
		width: 200,
		height: 30
	},
	style : {
		fontFamily: 'fantasy',
		fontSize :'15px',
		fontWeight: 'normal',
		fontStyle: 'normal',
		textDecoration: 'none',
		textAlign: 'center',
		lineHeight: '1.6', 
		margin: '0px',
		padding: '0px',
		verticalAlign: 'middle',
		alignItems: 'center'
	},
	selected: false,
	enableResizing : {
		bottom: false,
		bottomLeft: false,
		bottomRight: false,
		left: true,
		right: true,
		top: false,
		topLeft: false,
		topRight:false
	},
	rotation: 0,
	lockAspectRatio : false,
};



export {
	INITIAL_STATE_SQUARE,
	INITIAL_STATE_CIRCLE,
	INITIAL_STATE_ARROW,
	INITIAL_STATE_STAR,
	INITIAL_STATE_LINE,
	INITIAL_STATE_TRIANGLE,
	INITIAL_STATE_TABLE,
	INITIAL_STATE_IMAGE,
	INITIAL_STATE_FORM,
	INITIAL_STATE_TEXT
};
export default INITIAL_STATE_WORKSPACE;