import React from 'react';
import LeftColEdituer from './LeftColEditeur';
import RightColEdituer from './RightColEditeur';
import { connect } from 'react-redux';
import { getDocument } from 'api/workspace';
import { doSetState } from 'actions/workspace';
import ReactDrawer from 'react-drawer';
import Preview from './Preview';
import Save from './Save';
import Pdf from './Pdf';

import 'react-drawer/lib/react-drawer.css';

class Editor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id:null,
			open: false,
			position: 'top',
			noOverlay: false,
			layout : null};

		this.showPreview = this.showPreview.bind(this);
		this.doSaveDocument = this.doSaveDocument.bind(this);
		this.doRenderPdf = this.doRenderPdf.bind(this);
		
	}

	componentDidMount () {
		if (this.props.match.params.id) {
			getDocument(this.props.match.params.id)
				.then(res => {
					if (res.data) {
						this.props.doSetState(res.data);
					}
				
	  });
		}
		
	}


	toggleDrawer =  event => {
		this.setState({open: !this.state.open});
	};



	showPreview = event => {
		this.setState({open: true, layout : 'Preview'});
	};

	doSaveDocument = event => {
		this.setState({open: true, layout : 'Save'});
	};

	doRenderPdf = event => {
		this.setState({open: true, layout : 'Pdf'});
	};


    
	render() {

		const layout = (this.state.layout === 'Save')
			? (<Save open={this.state.open} toggleDrawer={this.toggleDrawer}/>)
			: ((this.state.layout === 'Preview') ? <Preview open={this.state.open} toggleDrawer={this.toggleDrawer}/> 
				: (<Pdf open={this.state.open} toggleDrawer={this.toggleDrawer}/>));

		return(
			<div>
				<div className="container_editeur d-flex">
					<LeftColEdituer />
					<RightColEdituer 
						doSaveDocument={this.doSaveDocument} 
						showPreview={this.showPreview} 
						doRenderPdf={this.doRenderPdf} />
				</div>
				<div className="container_finish_save">
					<ReactDrawer
						open={this.state.open}
						position={this.state.position}
						noOverlay={this.state.noOverlay}>
						{layout}
					</ReactDrawer>
				</div>
			</div>
		);
	}
}




const mapDispatchToProps = dispatch => ({
	doSetState: data => dispatch(doSetState(data)),
});


export default connect(
	null,
	mapDispatchToProps
)(Editor);
