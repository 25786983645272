export const SET_CANVAS = 'SET_CANVAS';
export const SET_HTML_DOC = 'SET_HTML_DOC';
export const SET_INFO_RECORDED_STATE = 'SET_INFO_RECORDED_STATE';
export const SET_STATE = 'SET_STATE';
export const DELETE = 'DELETE';
export const ADD_TABLE = 'ADD_TABLE';
export const ADD_IMAGE = 'ADD_IMAGE';
export const ADD_FORME = 'ADD_FORME';
export const ADD_TEXT = 'ADD_TEXT';
export const EDIT_TEXT = 'EDIT_TEXT';
export const EDIT_IMAGE = 'EDIT_IMAGE';
export const ADD_SQUARE = 'ADD_TEXT';
export const ADD_CIRCLE = 'ADD_CIRCLE';
export const ADD_ARROW = 'ADD_ARROW';
export const ADD_STAR = 'ADD_STAR';
export const ADD_LINE = 'ADD_LINE';
export const ADD_TRIANGLE = 'ADD_TRIANGLE';
export const DISABLE_DRAGGING = 'DISABLE_DRAGGING';
export const ENABLE_DRAGGING = 'ENABLE_DRAGGING';
export const SET_CONTENT_TABLE = 'SET_CONTENT_TABLE';
export const SET_SIZE = 'SET_SIZE';
export const SET_POSITION = 'SET_POSITION';
export const SET_LIVE_SIZE_POSITION = 'SET_LIVE_SIZE_POSITION';
export const TOGGLE_SELECTED = 'TOGGLE_SELECTED';
export const UNSELECT_ALLS = 'UNSELECT_ALLS';
export const SET_STYLE = 'SET_STYLE';
export const TOGGLE_FACE = 'TOGGLE_FACE';
export const DUPLICATE = 'DUPLICATE';
export const SET_ZOOM = 'SET_ZOOM';
