import React , { Component } from 'react';
import generatorSvgArrow from 'utils/generatorSvgArrow';


  
class Arrow extends Component {
	constructor(props) {
		super(props);
		this.state = {};
    
	}

	render() {

		const defaultStyle = {
			'fill':'#ccc2d1',
			'stroke':'#ccc2d1',
			'strokeWidth':'1',
		/*	'fillOpacity':'0.1',
			'strokeOpacity':'0.9'*/
		};

		const style = (this.props.ele.style)
			? Object.assign({}, defaultStyle, this.props.ele.style)
			: defaultStyle;

		let width = (this.props.ele.size.width !== 'auto')
			? this.props.ele.size.width
			: 100;
		let height = (this.props.ele.size.height !== 'auto')
			? this.props.ele.size.height
			: 100;

		const data = generatorSvgArrow({width: width, height: height}, this.props.ele.rotation);
      
		return (
			<svg height={height} width={width} viewBox={['0', '0', width, height].join(' ')}>
				<path d={data} style={style} />
			</svg>
		);
	}
    
}

export default Arrow;