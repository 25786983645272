import React , { Component } from 'react';

class Empty extends Component {


	render() {
    	return (
    		<div></div>
    	);}
}


  
export default Empty;