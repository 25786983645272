import {
	SET_ZOOM,
	SET_CANVAS,
	SET_HTML_DOC,
	SET_INFO_RECORDED_STATE,
	SET_STATE,
	DELETE,
	ADD_TABLE,
	ADD_IMAGE,
	EDIT_IMAGE,
	ADD_FORME,
	ADD_TEXT,
	EDIT_TEXT,
	ADD_SQUARE,
	ADD_CIRCLE,
	ADD_ARROW,
	ADD_STAR,
	ADD_LINE,
	ADD_TRIANGLE,
	SET_SIZE,
	SET_POSITION,
	SET_LIVE_SIZE_POSITION,
	TOGGLE_SELECTED,
	UNSELECT_ALLS,
	DISABLE_DRAGGING,
	ENABLE_DRAGGING,
	SET_CONTENT_TABLE,
	TOGGLE_FACE,
	SET_STYLE,
	DUPLICATE
} from '../constants/actions';

import INITIAL_STATE_WORKSPACE from '../initial_states/workspace';
// import { doDuplicate } from '../actions/workspace';

class Counter {
  static localID = 0;
  static get () {
  	this.localID ++;
  	return this.localID;
  }
}


const getMaxLocalID = (items) => {
	(items || []).map(item => {
		Counter.localID = (item.localID > Counter.localID)
			? item.localID
			: Counter.localID;
		return item;
	});
};


const setInfoRecordedState = (state, data) => {
	
	return ({
		...state,
		id: data.id,
		title: data.title
	});
};

const setZoom = (state, data) => {
	

	const OriginWidth = state.width / state.zoom;
	const OriginHeight = state.height / state.zoom;
	const newWidth = OriginWidth * data.zoom;
	const newHeight = OriginHeight * data.zoom;

	const items = [...state.items].map(item => {
		const {size, style} = item;

		const newSize = {
			width : (size.width * newWidth) / state.width,
			height : (size.height * newHeight) / state.height
		};
		const newPosition = {
			x : (item.position.x * newWidth) / state.width,
			y : (item.position.y * newHeight) / state.height,
		};


		return {
			...item, 
			size : newSize,
			style : (style['fontSize']) ? {...style, fontSize: ((Number(style['fontSize'].replace(/px/gi, '')) / state.zoom) * data.zoom) + 'px' } : style,
			position : newPosition
		};


	});

	return ({
		...state,
		zoom : data.zoom,
		width : newWidth,
		height : newHeight,
		items: items
	});
};


const setCanvas = (state, data) => {
	
	const activeFace = state.activeFace;
	const canvas = {};
	canvas[activeFace] = data;

	return ({
		...state,
		canvas: {...state.canvas, ...canvas}
	});
};


const setHtmlDoc = (state, data) => {
	
	const activeFace = state.activeFace;
	const domEle = {};
	domEle[activeFace] = data;

	return ({
		...state,
		html: {...state.html, ...domEle}
	});
};


const setState = (state, data) => {
	//getMaxLocalID(data.data);
	if (data.data) { getMaxLocalID(data.data); }
	return ({
		...state,
		id: data.id,
		title: data.title,
		html: data.html,
		items : data.data
	});
};

const addItem = (state, data) => {
	const newLocalID = Counter.get();
	const dataNewEle = {...data, localID : newLocalID, face : state.activeFace};
	return ({
		...state,
		items : (state.items) ? [...state.items, dataNewEle] : [dataNewEle]
	});
};

const doDuplicate = (state, data) => {
	const newLocalID = Counter.get();
	const dataNewEle = {...data, 
		localID : newLocalID, 
		face : state.activeFace,
		position : {x : data.position.x + 10, y: data.position.y + 10 }
	};


	const items = (state.items) 
		? state.items.map((ele, i) => {return Object.assign({}, ele, {selected: false, disableDragging: false}); })
		: [];

	return ({
		...state,
		items : [...items, dataNewEle]
	});
};


const deleteItem = (state, data) => ({
	...state,
	items : state.items.filter((ele)=> (ele.localID !== data.localID))
});
  
const setSize = (state, data) => ({
	...state,
	items : state.items.map((ele, i) => {
		if (ele.localID === data.localID) {
			return Object.assign({}, ele, {size: data.data});
		}
		return ele;
	})
});


const setStyle = (state, data) => ({
	...state,
	items : state.items.map((ele, i) => {
		if (ele.localID === data.localID) {
			return Object.assign({}, ele, {style: {...ele.style, ...data.style}});
		}
		return ele;
	})
});

const setPosition = (state, data) => ({
	...state,
	items : state.items.map((ele, i) => {
		if (ele.localID === data.localID) {
			return Object.assign({}, ele, {position: data.data});
		}
		return ele;
	})
});

const setLiveSizePosition = (state, data) => ({
	...state,
	items : state.items.map((ele, i) => {
		if (ele.localID === data.localID) {
			return Object.assign({}, ele, {size: data.size, position: data.position});
		}
		return ele;
	})
});

const toggleSelected = (state, data) => ({
	...state,
	items : state.items.map((ele, i) => {
		if (ele.localID === data.localID) {
			return Object.assign({}, ele, {selected: true});
		} 
		return Object.assign({}, ele, {selected: false});
    
	})
});


const toggleFace = (state, data) => ({
	...state,
	activeFace : data.face
});

const disableDragging = (state, data) => ({
	...state,
	items : state.items.map((ele, i) => {
		if (ele.localID === data.localID) {
			return Object.assign({}, ele, {disableDragging: true});
		} 
		return Object.assign({}, ele, {disableDragging: false});
	})
});


const enableDragging = (state, data) => ({
	...state,
	items : state.items.map((ele, i) => {
		if (ele.localID === data.localID) {
			return Object.assign({}, ele, {disableDragging: false});
		} 
		return ele;
	})
});

const unselectAll = (state, data) => ({
	...state,
	items : (state.items) 
		? state.items.map((ele, i) => {return Object.assign({}, ele, {selected: false, disableDragging: false}); })
		: []
		
	
});

const setContentTable = (state, data) => ({
	...state,
	items : state.items.map((ele, i) => {
		if (i === data.id) {
			return Object.assign({}, ele, {params: {...ele.params, dataTable: data.dataTable}});
		} 
		return ele;
	})
});


const editText = (state, data) => ({
	...state,
	items : state.items.map((ele) => {
		if (ele.localID === data.localID) {
			return Object.assign({}, ele, data);
		} 
		return ele;
	})
});

const editImage = (state, data) => ({
	...state,
	items : state.items.map((ele) => {
		if (ele.localID === data.localID) {
			return Object.assign({}, ele, data);
		} 
		return ele;
	})
});


function workspaceReducer(state = INITIAL_STATE_WORKSPACE, action) {
	switch(action.type) {
	case ADD_TABLE : 
	case ADD_IMAGE : 
	case ADD_FORME : 
	case ADD_TEXT : 
	case ADD_SQUARE : 
	case ADD_CIRCLE : 
	case ADD_ARROW : 
	case ADD_STAR : 
	case ADD_LINE : 
	case ADD_TRIANGLE : {
		return addItem(state, action.payload);
	}
	case EDIT_IMAGE : {
		return editImage(state, action.payload);
	}
	case EDIT_TEXT : {
		return editText(state, action.payload);
	}
	case DISABLE_DRAGGING : {
		return disableDragging(state, action.payload);
	}
	case ENABLE_DRAGGING : {
		return enableDragging(state, action.payload);
	}
	case DELETE : {
		return deleteItem(state, action.payload);
	}
	case SET_STYLE : {
		return setStyle(state, action.payload);
	}
	case SET_SIZE : {
		return setSize(state, action.payload);
	}
	case SET_POSITION : {
		return setPosition(state, action.payload);
	}
	case SET_LIVE_SIZE_POSITION : {
		return setLiveSizePosition(state, action.payload);
	}
	case TOGGLE_SELECTED : {
		return toggleSelected(state, action.payload);
	}
	case TOGGLE_FACE : {
		return toggleFace(state, action.payload);
	}
	case UNSELECT_ALLS : {
		return unselectAll(state, action.payload);
	}
	case SET_CONTENT_TABLE : {
		return setContentTable(state, action.payload);
	}
	case DUPLICATE : {
		return doDuplicate(state, action.payload);
	}
	case SET_STATE : {
		return setState(state, action.payload);
	}
	case SET_INFO_RECORDED_STATE : {
		return setInfoRecordedState(state, action.payload);
	}
	case SET_HTML_DOC : {
		return setHtmlDoc(state, action.payload);
	}
	case SET_CANVAS : {
		return setCanvas(state, action.payload);
	}
	case SET_ZOOM : {
		return setZoom(state, action.payload);
	}
	default : return state;
	}
}
  
export default workspaceReducer;