
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveDocument} from 'api/workspace';
import {NotificationManager} from 'react-notifications';
import { doSetInfoRecordedState } from 'actions/workspace';


class Save extends Component {


	constructor(props) {
		super(props);
		this.state = {
			title : this.props.workspace.title
		};
		this.save = this.save.bind(this);
	}

	handelChange (e) {
		this.setState({title: e.target.value});
	}

	save ()  {
	
		if (this.props.workspace.items) {
			saveDocument({id : this.props.workspace.id, title: this.state.title, html: this.props.workspace.html, data:this.props.workspace.items})
				.then(res => {
					this.props.doSetInfoRecordedState(res.data);
					NotificationManager.success('La document a été enregistrée avec succès', 'Enregistrement');
					this.props.toggleDrawer();
				});
		} else {
			NotificationManager.warning('Vous n\'avez rien fait', 'Enregistrement');
			this.props.toggleDrawer();
		}
	
	};

	render() {
		return (
			<div className="overlay open" id="overlay_editeur">
				<div className="d-flex container_finish_save">
					<div className="box-save-editeur text-center" style={{display : 'block'}}>
						<div className="text-center mt-40">
							<input type="text" onChange={(Event => this.handelChange(Event))} value={this.state.title} className="form-control" id="name_doc_editeur" placeholder="Titre du document" />
						</div>
						<div className="text-center mt-40">
							<button className="btn_medianet btn_product" onClick={this.save}   title="Enregistrer">Enregistrer</button>
						</div>
						<div className="text-center mt-35" onClick={this.props.toggleDrawer}><span className="reset_overlay">Annuler</span></div>
					</div>
				</div>
			</div>
		);
	}
}


const mapDispatchToProps = dispatch => ({
	doSetInfoRecordedState: data => dispatch(doSetInfoRecordedState(data)),
});

const mapStateToProps = state => ({
	workspace: state.workspaceState.present
});



export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Save);

