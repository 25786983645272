import React , { Component } from 'react';


  
class Circle extends Component {
	constructor(props) {
		super(props);
		this.state = {};
    
	}

	render() {

		const defaultStyle = {
			'fill':'#ccc2d1',
			'stroke':'#ccc2d1',
			'strokeWidth':'1',
		/*	'fillOpacity':'0.1',
			'strokeOpacity':'0.9'*/
		};

		const style = (this.props.ele.style)
			? Object.assign({}, defaultStyle, this.props.ele.style)
			: defaultStyle;

		const width = (this.props.ele.size.width !== 'auto')
			? this.props.ele.size.width
			: 100;
		const height = (this.props.ele.size.height !== 'auto')
			? this.props.ele.size.height
			: 100;
	  
		const strokeWidth = this.props.ele.style.strokeWidth;
		return (
			<svg height={height} width={width }>
				<ellipse cx={width / 2} cy={height / 2} rx={(width - strokeWidth) / 2} ry={(height - strokeWidth) / 2} style={style} />
			</svg>
		);
	}
    
}

export default Circle;