import {
	SET_ZOOM,
	SET_CANVAS,
	SET_HTML_DOC,
	SET_INFO_RECORDED_STATE,
	SET_STATE,
	ADD_TABLE,
	ADD_IMAGE,
	EDIT_IMAGE,
	ADD_FORME,
	ADD_TEXT,
	EDIT_TEXT,
	ADD_SQUARE,
	ADD_CIRCLE,
	ADD_ARROW,
	ADD_STAR,
	ADD_LINE,
	ADD_TRIANGLE,
	DELETE,
	SET_POSITION,
	SET_SIZE,
	SET_LIVE_SIZE_POSITION,
	TOGGLE_SELECTED,
	TOGGLE_FACE,
	DISABLE_DRAGGING,
	ENABLE_DRAGGING,
	UNSELECT_ALLS,
	SET_CONTENT_TABLE,
	SET_STYLE,
	DUPLICATE,
} from '../constants/actions';




const doSetCanvas = payload => ({
	type: SET_CANVAS,
	payload : payload,
});


const doSetHtmlDoc = payload => ({
	type: SET_HTML_DOC,
	payload : payload,
});


const doSetInfoRecordedState = payload => ({
	type: SET_INFO_RECORDED_STATE,
	payload : payload,
});


const doSetState = payload => ({
	type: SET_STATE,
	payload : payload,
});

const doDuplicate = payload => ({
	type: DUPLICATE,
	payload : payload,
});

const doDelete = payload => ({
	type: DELETE,
	payload : payload,
});


const doAddSquare = payload => ({
	type: ADD_SQUARE,
	payload : payload,
});


const doAddCircle = payload => ({
	type: ADD_CIRCLE,
	payload : payload,
});


const doAddArrow = payload => ({
	type: ADD_ARROW,
	payload : payload,
});

const doAddStar = payload => ({
	type: ADD_STAR,
	payload : payload,
});

 

const doAddLine = payload => ({
	type: ADD_LINE,
	payload : payload,
});

const doAddTriangle = payload => ({
	type: ADD_TRIANGLE,
	payload : payload,
});

const doAddTable = payload => ({
	type: ADD_TABLE,
	payload : payload,
});
  
const doAddImage = payload => ({
	type: ADD_IMAGE,
	payload : payload,
});


const doEditImage = payload => ({
	type: EDIT_IMAGE,
	payload : payload,
});

const doAddForme = payload => ({
	type: ADD_FORME,
	payload : payload,
});

const doAddText = payload => ({
	type: ADD_TEXT,
	payload : payload,
});
  


const doEditText = payload => ({
	type: EDIT_TEXT,
	payload : payload,
});

const setContentTable = payload => ({
	type: SET_CONTENT_TABLE,
	payload : payload,
});

const setSize = payload => ({
	type: SET_SIZE,
	payload : payload,
});

const setPosition = payload => ({
	type: SET_POSITION,
	payload : payload,
});

const setLiveSizePosition = payload => ({
	type: SET_LIVE_SIZE_POSITION,
	payload : payload,
});


const disableDragging = payload => ({
	type: DISABLE_DRAGGING,
	payload : payload,
});

const enableDragging = payload => ({
	type: ENABLE_DRAGGING,
	payload : payload,
});

  

const toggleSelected = payload => ({
	type: TOGGLE_SELECTED,
	payload : payload,
});

const toggleFace = payload => ({
	type: TOGGLE_FACE,
	payload : payload,
});

const unselectAll = payload => ({
	type: UNSELECT_ALLS,
	payload : payload,
});

const setStyle = payload => ({
	type: SET_STYLE,
	payload : payload,
});


const doSetZoom = payload => ({
	type: SET_ZOOM,
	payload : payload,
});




  
export {
	doSetZoom,
	doSetCanvas,
	doSetHtmlDoc,
	doSetInfoRecordedState,
	doSetState,
	doDelete,
	doDuplicate,
	doAddTable,
	doAddImage,
	doEditImage,
	doAddForme,
	doAddText,
	doEditText,
	doAddTriangle,
	doAddLine,
	doAddStar,
	doAddCircle,
	doAddArrow,
	doAddSquare,
	disableDragging,
	enableDragging,
	setSize,
	setPosition,
	setLiveSizePosition,
	toggleSelected,
	toggleFace,
	unselectAll,
	setContentTable,
	setStyle
};