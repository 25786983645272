const getPathStar = (size, teeths, d1 = 1, d2, d3, _splay) => {

	
	// Center
	const c = size / 2;

	// Radii
	const r1 = d1 * size / 2;
	const r2 = d2 * size / 2;
	const r3 = d3 * size / 2;

	// Angle
	const angle = 360 / teeths;
	const offset = 90;

	const rad = (a) => {
		return Math.PI * a / 180;
	};
	
	const rx = (r, a) => {
		return c + r * Math.cos(rad(a));
	};
	
	const ry = (r, a) =>{
		return c + r * Math.sin(rad(a));
	};
	
	const num = (n) => {
		return (n < 0.0000001) ? 0 : n;
	};
	
	// Angle offsets to splay tooth
	const ta = angle / 4;
	const splay = _splay * ta;
	const tw = Math.tan(rad(ta - splay)) * r1;
	
	// Flat tooth end x and y coordinates
	const tx = (a, w) => {
		return Math.sin(rad(a)) * w;
	};
	
	const ty = (a, w) => {
		return Math.cos(rad(a)) * w;
	};
	

	const drawTeeth = (n) => {
		var d = [];
		for (var i = 0; i < n; i++) {
			var a = angle * i - offset;
			var a1 = a + ta + splay;
			var a2 = a + angle - ta - splay;
			var line = [
				(i === 0) ? 'M' : 'L',
				num(rx(r1, a) + tx(a, tw)),
				num(ry(r1, a) - ty(a, tw)),
				'L',
				num(rx(r1, a) - tx(a, tw)),
				num(ry(r1, a) + ty(a, tw)),
				'L',
				num(rx(r2, a1)),
				num(ry(r2, a1)),
				'A', r2, r2,
				'0 0 1',
				num(rx(r2, a2)),
				num(ry(r2, a2)),
			].join(' ');
			d.push(line);
		}
		d.push(' Z');
		return d.join(' ');
	};


	const hole = () => {
		return [
			'M', c, c - r3,
			'A', r3, r3,
			'0 0 0',
			c, c + r3,
			'A', r3, r3,
			'0 0 0',
			c, c - r3,
		].join(' ');
	};

	const pathData = [
		drawTeeth(teeths),
		hole()
	].join(' ');
	

	return pathData;
	
};
  

export default getPathStar;