import React , { Component } from 'react';
import { connect } from 'react-redux';
import InputRange from 'react-input-range';
import { setStyle } from 'actions/workspace';

class Contour extends Component {

	constructor(props, ctx) {
		super(props, ctx);
		this.state = {size : 7};

	}

	handleChange = (size) => {
		this.props.setStyle({localID: this.props.ele.localID, style : {strokeWidth: size }});
	}
    

	render() {

		const size = (this.props.ele.style.strokeWidth)
			? this.props.ele.style.strokeWidth
			: 1;
    	return (
			<div className="container_nbr_col">
				<div className="box_nbr_col d-flex ">
					<input className="inputstate" type="text" readOnly value={size}/>	
					
					<InputRange
						maxValue={20}
						step={1}
						minValue={1} 
						value={size}
						onChange={value => this.handleChange(value)} />
				</div>
			</div>
    	);}
}



const mapDispatchToProps = dispatch => ({
	setStyle: data => dispatch(setStyle(data)),
});
  
export default connect(
	null,
	mapDispatchToProps
)(Contour);