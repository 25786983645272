import React , { Component } from 'react';
import { connect } from 'react-redux';
import InputRange from 'react-input-range';
import { setStyle } from 'actions/workspace';

class Size extends Component {

	constructor(props, ctx) {
		super(props, ctx);
		this.state = {size : 6};

	}

	handleChange = (size) => {
		 this.props.setStyle({localID: this.props.ele.localID, style : {fontSize: size + 'px'}});
	}
    

	render() {

		const size = (this.props.ele.style.fontSize)
			? Number(this.props.ele.style.fontSize.replace(/px/gi, ''))
			: 7;
    	return (
			<div className="container_nbr_col">
				<div className="box_nbr_col d-flex ">
					<input className="inputstate" 
						min={6} max={100} 
						type="number" 
						value={size}
						onChange={(event) => this.handleChange(event.target.value)}  
					/>	
					
					<InputRange
						maxValue={100}
						step={1}
						minValue={6} 
						value={size}
						onChange={value => this.handleChange(value)} />
				</div>
			</div>
    	);}
}



const mapDispatchToProps = dispatch => ({
	setStyle: data => dispatch(setStyle(data)),
});
  
export default connect(
	null,
	mapDispatchToProps
)(Size);