import React , { Component } from 'react';
import { connect } from 'react-redux';
import { FRONT, BACK } from 'constants/faces';
import { 
	toggleFace, unselectAll
} from '../actions/workspace';

class Face extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
    
    
	toggleFace(data) {
		this.props.unselectAll({});
		this.props.toggleFace(data);
	}

	render() {
		return (

			<div className="row_face_editeur justify-content-center d-flex py-30">
				<button onClick={() => { this.toggleFace({face : FRONT}); }} className={(this.props.activeFace === FRONT ) ? 'btn_face_editeur active' : 'btn_face_editeur'}> Face avant</button>
				<button onClick={() => { this.toggleFace({face : BACK}); }} className={(this.props.activeFace === BACK ) ? 'btn_face_editeur active' : 'btn_face_editeur'}> Face arrière</button>
			</div>

		);}
}
  

const mapStateToProps = state => ({
	activeFace: state.workspaceState.present.activeFace,
});


const mapDispatchToProps = dispatch => ({
	toggleFace: data => dispatch(toggleFace(data)),
	unselectAll: data => dispatch(unselectAll(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Face);
  

