import React , { Component } from 'react';
import { connect } from 'react-redux';
import { doEditText } from 'actions/workspace';

class SpecialCharacters extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	addCharacter = (e) => {
		const newText = this.props.ele.params.text + e.target.innerHTML;
    	const newStateText = Object.assign({},this.props.ele, {...this.props.ele, params:{text: newText}});
    	this.props.doEditText(newStateText);
	}
    

	render() {
    
		return (<div className="special-characters-picker tool-content">
			<span className="special-character" onClick={this.addCharacter}>‚</span>
			<span className="special-character" onClick={this.addCharacter}>ƒ</span>
			<span className="special-character" onClick={this.addCharacter}>„</span>
			<span className="special-character" onClick={this.addCharacter}>…</span>
			<span className="special-character" onClick={this.addCharacter}>†</span>
			<span className="special-character" onClick={this.addCharacter}>‡</span>
			<span className="special-character" onClick={this.addCharacter}>ˆ</span>
			<span className="special-character" onClick={this.addCharacter}>‰</span>
			<span className="special-character" onClick={this.addCharacter}>Š</span>
			<span className="special-character" onClick={this.addCharacter}>‹</span>
			<span className="special-character" onClick={this.addCharacter}>Œ</span>
			<span className="special-character" onClick={this.addCharacter}>‘</span>
			<span className="special-character" onClick={this.addCharacter}>’</span>
			<span className="special-character" onClick={this.addCharacter}>“</span>
			<span className="special-character" onClick={this.addCharacter}>”</span>
			<span className="special-character" onClick={this.addCharacter}>•</span>
			<span className="special-character" onClick={this.addCharacter}>–</span>
			<span className="special-character" onClick={this.addCharacter}>—</span>
			<span className="special-character" onClick={this.addCharacter}>˜</span>
			<span className="special-character" onClick={this.addCharacter}>™</span>
			<span className="special-character" onClick={this.addCharacter}>š</span>
			<span className="special-character" onClick={this.addCharacter}>›</span>
			<span className="special-character" onClick={this.addCharacter}>œ</span>
			<span className="special-character" onClick={this.addCharacter}>Ÿ</span>
			<span className="special-character" onClick={this.addCharacter}>¡</span>
			<span className="special-character" onClick={this.addCharacter}>¢</span>
			<span className="special-character" onClick={this.addCharacter}>£</span>
			<span className="special-character" onClick={this.addCharacter}>¤</span>
			<span className="special-character" onClick={this.addCharacter}>¥</span>
			<span className="special-character" onClick={this.addCharacter}>¦</span>
			<span className="special-character" onClick={this.addCharacter}>§</span>
			<span className="special-character" onClick={this.addCharacter}>¨</span>
			<span className="special-character" onClick={this.addCharacter}>©</span>
			<span className="special-character" onClick={this.addCharacter}>ª</span>
			<span className="special-character" onClick={this.addCharacter}>«</span>
			<span className="special-character" onClick={this.addCharacter}>¬</span>
			<span className="special-character" onClick={this.addCharacter}>®</span>
			<span className="special-character" onClick={this.addCharacter}>¯</span>
			<span className="special-character" onClick={this.addCharacter}>°</span>
			<span className="special-character" onClick={this.addCharacter}>±</span>
			<span className="special-character" onClick={this.addCharacter}>²</span>
			<span className="special-character" onClick={this.addCharacter}>³</span>
			<span className="special-character" onClick={this.addCharacter}>´</span>
			<span className="special-character" onClick={this.addCharacter}>¶</span>
			<span className="special-character" onClick={this.addCharacter}>·</span>
			<span className="special-character" onClick={this.addCharacter}>¸</span>
			<span className="special-character" onClick={this.addCharacter}>¹</span>
			<span className="special-character" onClick={this.addCharacter}>º</span>
			<span className="special-character" onClick={this.addCharacter}>»</span>
			<span className="special-character" onClick={this.addCharacter}>¼</span>
			<span className="special-character" onClick={this.addCharacter}>½</span>
			<span className="special-character" onClick={this.addCharacter}>¾</span>
			<span className="special-character" onClick={this.addCharacter}>¿</span>
			<span className="special-character" onClick={this.addCharacter}>À</span>
			<span className="special-character" onClick={this.addCharacter}>Á</span>
			<span className="special-character" onClick={this.addCharacter}>Â</span>
			<span className="special-character" onClick={this.addCharacter}>Ã</span>
			<span className="special-character" onClick={this.addCharacter}>Ä</span>
			<span className="special-character" onClick={this.addCharacter}>Å</span>
			<span className="special-character" onClick={this.addCharacter}>Æ</span>
			<span className="special-character" onClick={this.addCharacter}>Ç</span>
			<span className="special-character" onClick={this.addCharacter}>È</span>
			<span className="special-character" onClick={this.addCharacter}>É</span>
			<span className="special-character" onClick={this.addCharacter}>Ê</span>
			<span className="special-character" onClick={this.addCharacter}>Ë</span>
			<span className="special-character" onClick={this.addCharacter}>Ì</span>
			<span className="special-character" onClick={this.addCharacter}>Í</span>
			<span className="special-character" onClick={this.addCharacter}>Î</span>
			<span className="special-character" onClick={this.addCharacter}>Ï</span>
			<span className="special-character" onClick={this.addCharacter}>Ð</span>
			<span className="special-character" onClick={this.addCharacter}>Ñ</span>
			<span className="special-character" onClick={this.addCharacter}>Ò</span>
			<span className="special-character" onClick={this.addCharacter}>Ó</span>
			<span className="special-character" onClick={this.addCharacter}>Ô</span>
			<span className="special-character" onClick={this.addCharacter}>Õ</span>
			<span className="special-character" onClick={this.addCharacter}>Ö</span>
			<span className="special-character" onClick={this.addCharacter}>×</span>
			<span className="special-character" onClick={this.addCharacter}>Ø</span>
			<span className="special-character" onClick={this.addCharacter}>Ù</span>
			<span className="special-character" onClick={this.addCharacter}>Ú</span>
			<span className="special-character" onClick={this.addCharacter}>Û</span>
			<span className="special-character" onClick={this.addCharacter}>Ü</span>
			<span className="special-character" onClick={this.addCharacter}>Ý</span>
			<span className="special-character" onClick={this.addCharacter}>Þ</span>
			<span className="special-character" onClick={this.addCharacter}>ß</span>
			<span className="special-character" onClick={this.addCharacter}>à</span>
			<span className="special-character" onClick={this.addCharacter}>á</span>
			<span className="special-character" onClick={this.addCharacter}>â</span>
			<span className="special-character" onClick={this.addCharacter}>ã</span>
			<span className="special-character" onClick={this.addCharacter}>ä</span>
			<span className="special-character" onClick={this.addCharacter}>å</span>
			<span className="special-character" onClick={this.addCharacter}>æ</span>
			<span className="special-character" onClick={this.addCharacter}>ç</span>
			<span className="special-character" onClick={this.addCharacter}>è</span>
			<span className="special-character" onClick={this.addCharacter}>é</span>
			<span className="special-character" onClick={this.addCharacter}>ê</span>
			<span className="special-character" onClick={this.addCharacter}>ë</span>
			<span className="special-character" onClick={this.addCharacter}>ì</span>
			<span className="special-character" onClick={this.addCharacter}>í</span>
			<span className="special-character" onClick={this.addCharacter}>î</span>
			<span className="special-character" onClick={this.addCharacter}>ï</span>
			<span className="special-character" onClick={this.addCharacter}>ð</span>
			<span className="special-character" onClick={this.addCharacter}>ñ</span>
			<span className="special-character" onClick={this.addCharacter}>ò</span>
			<span className="special-character" onClick={this.addCharacter}>ó</span>
			<span className="special-character" onClick={this.addCharacter}>ô</span>
			<span className="special-character" onClick={this.addCharacter}>õ</span>
			<span className="special-character" onClick={this.addCharacter}>ö</span>
			<span className="special-character" onClick={this.addCharacter}>÷</span>
			<span className="special-character" onClick={this.addCharacter}>ø</span>
			<span className="special-character" onClick={this.addCharacter}>ù</span>
			<span className="special-character" onClick={this.addCharacter}>ú</span>
			<span className="special-character" onClick={this.addCharacter}>û</span>
			<span className="special-character" onClick={this.addCharacter}>ü</span>
			<span className="special-character" onClick={this.addCharacter}>ý</span>
			<span className="special-character" onClick={this.addCharacter}>þ</span>
			<span className="special-character" onClick={this.addCharacter}>ÿ</span>
			<span className="special-character" onClick={this.addCharacter}>€</span>
			<span className="special-character" onClick={this.addCharacter}>!</span>
			<span className="special-character" onClick={this.addCharacter}>"</span>
			<span className="special-character" onClick={this.addCharacter}>#</span>
			<span className="special-character" onClick={this.addCharacter}>$</span>
			<span className="special-character" onClick={this.addCharacter}>%</span>
			<span className="special-character" onClick={this.addCharacter}>&amp;</span>
			<span className="special-character" onClick={this.addCharacter}>'</span>
			<span className="special-character" onClick={this.addCharacter}>(</span>
			<span className="special-character" onClick={this.addCharacter}>)</span>
			<span className="special-character" onClick={this.addCharacter}>*</span>
			<span className="special-character" onClick={this.addCharacter}>+</span>
			<span className="special-character" onClick={this.addCharacter}>,</span>
			<span className="special-character" onClick={this.addCharacter}>-</span>
			<span className="special-character" onClick={this.addCharacter}>.</span>
			<span className="special-character" onClick={this.addCharacter}>/</span>
			<span className="special-character" onClick={this.addCharacter}>0</span>
			<span className="special-character" onClick={this.addCharacter}>1</span>
			<span className="special-character" onClick={this.addCharacter}>2</span>
			<span className="special-character" onClick={this.addCharacter}>3</span>
			<span className="special-character" onClick={this.addCharacter}>4</span>
			<span className="special-character" onClick={this.addCharacter}>5</span>
			<span className="special-character" onClick={this.addCharacter}>6</span>
			<span className="special-character" onClick={this.addCharacter}>7</span>
			<span className="special-character" onClick={this.addCharacter}>8</span>
			<span className="special-character" onClick={this.addCharacter}>9</span>
			<span className="special-character" onClick={this.addCharacter}>:</span>
			<span className="special-character" onClick={this.addCharacter}>;</span>
			<span className="special-character" onClick={this.addCharacter}>&lt;</span>
			<span className="special-character" onClick={this.addCharacter}>=</span>
			<span className="special-character" onClick={this.addCharacter}>&gt;</span>
			<span className="special-character" onClick={this.addCharacter}>?</span>
			<span className="special-character" onClick={this.addCharacter}>@</span>
			<span className="special-character" onClick={this.addCharacter}>A</span>
			<span className="special-character" onClick={this.addCharacter}>B</span>
			<span className="special-character" onClick={this.addCharacter}>C</span>
			<span className="special-character" onClick={this.addCharacter}>D</span>
			<span className="special-character" onClick={this.addCharacter}>E</span>
			<span className="special-character" onClick={this.addCharacter}>F</span>
			<span className="special-character" onClick={this.addCharacter}>G</span>
			<span className="special-character" onClick={this.addCharacter}>H</span>
			<span className="special-character" onClick={this.addCharacter}>I</span>
			<span className="special-character" onClick={this.addCharacter}>J</span>
			<span className="special-character" onClick={this.addCharacter}>K</span>
			<span className="special-character" onClick={this.addCharacter}>L</span>
			<span className="special-character" onClick={this.addCharacter}>M</span>
			<span className="special-character" onClick={this.addCharacter}>N</span>
			<span className="special-character" onClick={this.addCharacter}>O</span>
			<span className="special-character" onClick={this.addCharacter}>P</span>
			<span className="special-character" onClick={this.addCharacter}>Q</span>
			<span className="special-character" onClick={this.addCharacter}>R</span>
			<span className="special-character" onClick={this.addCharacter}>S</span>
			<span className="special-character" onClick={this.addCharacter}>T</span>
			<span className="special-character" onClick={this.addCharacter}>U</span>
			<span className="special-character" onClick={this.addCharacter}>V</span>
			<span className="special-character" onClick={this.addCharacter}>W</span>
			<span className="special-character" onClick={this.addCharacter}>X</span>
			<span className="special-character" onClick={this.addCharacter}>Y</span>
			<span className="special-character" onClick={this.addCharacter}>Z</span>
			<span className="special-character" onClick={this.addCharacter}>[</span>
			<span className="special-character" onClick={this.addCharacter}>\</span>
			<span className="special-character" onClick={this.addCharacter}>]</span>
			<span className="special-character" onClick={this.addCharacter}>^</span>
			<span className="special-character" onClick={this.addCharacter}>_</span>
			<span className="special-character" onClick={this.addCharacter}>`</span>
			<span className="special-character" onClick={this.addCharacter}>a</span>
			<span className="special-character" onClick={this.addCharacter}>b</span>
			<span className="special-character" onClick={this.addCharacter}>c</span>
			<span className="special-character" onClick={this.addCharacter}>d</span>
			<span className="special-character" onClick={this.addCharacter}>e</span>
			<span className="special-character" onClick={this.addCharacter}>f</span>
			<span className="special-character" onClick={this.addCharacter}>g</span>
			<span className="special-character" onClick={this.addCharacter}>h</span>
			<span className="special-character" onClick={this.addCharacter}>i</span>
			<span className="special-character" onClick={this.addCharacter}>j</span>
			<span className="special-character" onClick={this.addCharacter}>k</span>
			<span className="special-character" onClick={this.addCharacter}>l</span>
			<span className="special-character" onClick={this.addCharacter}>m</span>
			<span className="special-character" onClick={this.addCharacter}>n</span>
			<span className="special-character" onClick={this.addCharacter}>o</span>
			<span className="special-character" onClick={this.addCharacter}>p</span>
			<span className="special-character" onClick={this.addCharacter}>q</span>
			<span className="special-character" onClick={this.addCharacter}>r</span>
			<span className="special-character" onClick={this.addCharacter}>s</span>
			<span className="special-character" onClick={this.addCharacter}>t</span>
			<span className="special-character" onClick={this.addCharacter}>u</span>
			<span className="special-character" onClick={this.addCharacter}>v</span>
			<span className="special-character" onClick={this.addCharacter}>w</span>
			<span className="special-character" onClick={this.addCharacter}>x</span>
			<span className="special-character" onClick={this.addCharacter}>y</span>
			<span className="special-character" onClick={this.addCharacter}>z</span>
			<span className="special-character" onClick={this.addCharacter}>{} </span>
			<span className="special-character" onClick={this.addCharacter}>|</span>
			<span className="special-character" onClick={this.addCharacter}>}</span>
			<span className="special-character" onClick={this.addCharacter}>~</span>
			<span className="special-character" onClick={this.addCharacter}>₩</span>
			<span className="special-character" onClick={this.addCharacter}> </span>
		</div>);}
}


const mapDispatchToProps = dispatch => ({
	doEditText: data => dispatch(doEditText(data)),
});
  
export default connect(
	null,
	mapDispatchToProps
)(SpecialCharacters);