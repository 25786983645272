import React , { Component } from 'react';
import { connect } from 'react-redux';
import { setContentTable } from 'actions/workspace';
import './FormEditCel.css';



class FormEditCel extends Component {
	constructor(props) {
		super(props);
		this.state = {dataTable : this.props.dataTable};
		this.textInput = React.createRef();
		this.focusTextInput = this.focusTextInput.bind(this);
	}

	componentDidMount() {
		this.focusTextInput();
	}


	focusTextInput() {
		this.textInput.current.focus();
	  }

	onChange(event) {

		const { value } = event.target;
		const dataTable = this.state.dataTable;
		const newCelVal = {};
		newCelVal[ this.props.col] = value;
		dataTable[this.props.row] = Object.assign({}, dataTable[this.props.row], {...dataTable[this.props.row], ...newCelVal});
		this.props.setContentTable({
			id: this.props.index, 
			dataTable: dataTable });
        
	}


 

	render() {
		return (
			<textarea 
				ref={this.textInput} 
				className="cell_input"
				type="text" 
				placeholder=""
				value={this.state.dataTable[this.props.row][this.props.col]}
				onChange={(event) => this.onChange(event)}
			></textarea>
           
		);
	}
    
}


const mapDispatchToProps = dispatch => ({
	setContentTable: data => dispatch(setContentTable(data)),
});

export default connect(
	null,
	mapDispatchToProps
)(FormEditCel);

