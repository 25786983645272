import React from 'react';
import Editor from './Editor';
import Documents from './Documents';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

// import Save from './save';



const App = () => (
	<div>
		<Router>
			<Route path="/" exact render={() => ( <Redirect to="/editor/"/> )} />
			<Route path="/editor/:id?"   component={Editor} />
			<Route path="/documents/"  component={Documents} />
		</Router>
		<NotificationContainer/>
	</div>
	
);

export default App;
