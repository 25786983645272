


function convert2List (content, parentNode = 'ol') {

	const toggledNode = (parentNode === 'ol') ? 'ul' : 'ol';
	let contentHtml = '';
	const textItems = [];
	const parser = new DOMParser();
	const domItems = parser.parseFromString(content, 'text/html');
	const bodyContent = domItems.querySelector('body');
	if (bodyContent.querySelector(parentNode)) {
		bodyContent.querySelector(parentNode).childNodes.forEach(node => {
			textItems.push(node.textContent);
			contentHtml  += node.textContent+ '<br />';
		});
	} else if (bodyContent.querySelector(toggledNode)){
		bodyContent.querySelector(toggledNode).childNodes.forEach(node => {
			contentHtml  += (node.textContent) ? '<li>' + node.textContent + '</li>' : '';
		});
		contentHtml = '<' + parentNode + '>' + contentHtml + '</' + parentNode + '>';
	} else if (bodyContent.childNodes){
		bodyContent.childNodes.forEach(node => {
			contentHtml  += (node.textContent) ? '<li>' + node.textContent + '</li>' : '';
		});
		contentHtml = '<' + parentNode + '>' + contentHtml + '</' + parentNode + '>';
	}
    
	return contentHtml;
};

export default convert2List;