import React , { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import InputRange from 'react-input-range';
import { doEditImage } from 'actions/workspace';
// import Jimp from 'jimp';

class InputRangeColor extends PureComponent {
	constructor(props, ctx) {
		super(props, ctx);
		this.state = {
			value: this.props.intialValue
		};
	}


	render() {
		return (
			<div className="container_nbr_col">
				<span className="caption_nbr_col">{this.props.label}</span>
				<div className="box_nbr_col d-flex ">	
					<InputRange
						maxValue={this.props.max}
						step={this.props.step}
						minValue={this.props.min} 
						value={this.state.value}
						onChange={value => {this.props.onChangeValue(value); this.setState({value : value});}}
					/>
					
				</div>
			</div>
		);
	}

}




class Color extends Component {

	constructor(props, ctx) {
		super(props, ctx);
		this.state = {
			src: this.props.ele.params.src,
			err: null,
			colors : {
				saturate : 0,
				lighten : 0,
			}
		};
		this._handleChangeSaturate = this._handleChangeSaturate.bind(this);
		this._handleChangeLighten = this._handleChangeLighten.bind(this);
		this._handleChangeHue = this._handleChangeHue.bind(this);
		
	}


	applyFilterCss (filter) {
		const newFilterCss = Object.assign({}, this.props.ele.params.filterCss, 
			{
				lighten: filter.lighten, 
				brighten: filter.brighten, 
				saturate: filter.saturate, 
				desaturate: filter.desaturate, 
				hue: filter.hue});
		const newParams = Object.assign({}, this.props.ele.params, {src: this.props.ele.params.src, filterCss : newFilterCss });
		const newState = Object.assign({}, this.props.ele, {params: newParams});
		this.props.doEditImage(newState);
	}


	_handleChangeSaturate (value) {

		const filter = {
			...this.props.ele.params.filterCss, 
			saturate : value, 
			desaturate :  value
		};
		  this.applyFilterCss(filter);
	}

	_handleChangeLighten (value) {
		
		const filter = {
			...this.props.ele.params.filterCss, 
			brighten : value,
		};
		 this.applyFilterCss(filter);
	}

	_handleChangeHue (value) {
		const filter = {...this.props.ele.params.filterCss, hue : value};
		this.applyFilterCss(filter);
	}

	render() {

		const {hue, saturate, brighten} = this.props.ele.params.filterCss;
		const intialValueSaturate = saturate;
		const intialVaLuelighten = brighten;
    	return (
			<div className="row">
				<div className="col-md-12">
					<div className="box_slide_forms container_etoile_editeur starRange" style={{display: 'block'}}>
						<InputRangeColor intialValue={hue} label="Teinte" min={0} max = {360} step= {1} onChangeValue={(value) => this._handleChangeHue(value)} />
						<InputRangeColor intialValue={intialValueSaturate} label="Saturation" min={0} max = {3} step= {0.125} onChangeValue={(value) => this._handleChangeSaturate(value)} />
						<InputRangeColor intialValue={intialVaLuelighten} label="Luminosité" min={0} max = {200} step= {0.5} onChangeValue={(value) => this._handleChangeLighten(value)} />
					</div>
				</div>
			</div>
    	);}
}



const mapDispatchToProps = dispatch => ({
	doEditImage: data => dispatch(doEditImage(data)),
});
  
export default connect(
	null,
	mapDispatchToProps
)(Color);