import React , { Component } from 'react';
import { connect } from 'react-redux';
import Viewer from './viewer';
import { unselectAll, setPosition, doDelete, doSetHtmlDoc, doSetCanvas } from 'actions/workspace';
import { getCurrentFaceItems } from 'selectors/items';
// import { convertDom2Image, convertHtml2image, jsHtml2pdf } from '../../api/workspace';
import './index.css';


class WorkSpace extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.ref = React.createRef();

	}

	selectEle = null;

	componentDidMount() {
		var keyPressed = [];

		document.addEventListener('keyup', (event) => { 
			// event.preventDefault();
			const key = event.key;
			if (key === 'Control') {
				keyPressed = [];
			}
		});
		document.addEventListener('keydown', (event) => { 
			// event.preventDefault();
			const key = event.key;

			if (keyPressed[0] === 'Control') {
				keyPressed[1] = key;
			} else {
				keyPressed.push(key);
			}
		

			

			if (keyPressed[0] === 'Control' && keyPressed[1] === 'z') {
				console.log('keyPressed::: ', keyPressed);
				//keyPressed = []
			}

			if (this.selectEle !== null) {
				let position = this.selectEle.position;
				switch (key) {
				case 'Escape': 	
					this.props.unselectAll(null); 
					break;
				case 'ArrowUp': 
					position = {...this.selectEle.position, y: this.selectEle.position.y - 1};
					this.props.setPosition ({localID: this.selectEle.localID, data: position});
					break;
				case 'ArrowDown': 
				 	position = {...this.selectEle.position, y: this.selectEle.position.y + 1};
					this.props.setPosition ({localID: this.selectEle.localID, data: position});
					break;
				case 'ArrowRight': 
				 	position = {...this.selectEle.position, x: this.selectEle.position.x + 1};
					this.props.setPosition ({localID: this.selectEle.localID, data: position});
					break;
				case 'ArrowLeft': 
				 	position = {...this.selectEle.position, x: this.selectEle.position.x - 1};
					this.props.setPosition ({localID: this.selectEle.localID, data: position});
					break;
				case 'Delete': 
					position = {...this.selectEle.position, x: this.selectEle.position.x - 1};
			   		this.props.doDelete ({localID: this.selectEle.localID});
			   break;
				default: break;
				}
			}
			
		});

		

		
		
	  }

	  componentDidUpdate() {



		const activeFace = this.props.activeFace;
		const idCurrentDiv = 'canvas' + activeFace;
		['FRONT', 'BACK'].map(face => {

			if (face !== activeFace) {
				const canvasHtml = (this.props.html[face])
					? this.props.html[face]
					: '';

				const idCurrentDiv = 'canvas' + face;
				const canvas = document.getElementById(idCurrentDiv);


				if (canvas.outerHTML !== canvasHtml) {
					
					const parser = new DOMParser();
					const wrapper = parser.parseFromString(canvasHtml, 'text/html');
					const domBlocEditor = wrapper.body.firstChild;

					if (domBlocEditor) {
						canvas.appendChild(domBlocEditor);
					}
			   }

			}

			return face;
			
		});

		
		const canvasHtml = (this.props.html[activeFace])
			? this.props.html[activeFace]
			: '';

		if (this.ref.current.outerHTML !== canvasHtml) {
			 this.props.doSetHtmlDoc(this.ref.current.outerHTML);
		}
		
		const canvas = document.getElementById(idCurrentDiv);
		while (canvas.firstChild) {
			canvas.removeChild(canvas.firstChild);
		}
	
		const parser = new DOMParser();
		const wrapper = parser.parseFromString(canvasHtml, 'text/html');
		const domBlocEditor = wrapper.body.firstChild;

		if (domBlocEditor) {
			canvas.appendChild(domBlocEditor);
		}

		
	  }

	render() {
		this.selectEle = null;
		return (
			<div style={{position:'relative'}}>

				<div id="canvasFRONT" className="bloc_editeur" data-width="1000" data-height="600" data-step="25" data-max="200" style={{'zIndex': '-100', 'width': '1000px',  'height': '600px', position:'absolute', top: '0', left:'0'}}> FRONT</div>
				<div id="canvasBACK" className="bloc_editeur" data-width="1000" data-height="600" data-step="25" data-max="200" style={{'zIndex': '-101', 'width': '1000px',  'height': '600px', position:'absolute', top: '0', left:'0'}}> BACK</div>
				<div ref={this.ref} className="bloc_editeur" id="canvas"  data-step="25" data-max="200" style={{'width': this.props.width + 'px',  'height': this.props.height + 'px', position:'relative', padding: (1.875 * this.props.zoom) + 'rem'}}>
					<div className="securite_bloc_editeur" onClick={(event) => {
						event.preventDefault();
						if (event.currentTarget === event.target) {
							this.props.unselectAll(null);
						}
					}}>
						{(this.props.items || []).map((ele, i) => {
							this.selectEle = (ele.selected) ? ele : this.selectEle;
							return (<Viewer zoom = {this.props.zoom} key = {i} index = {i} ele = {ele}></Viewer>);
						}
						
						)}
					</div>
				</div>
			</div>
		);}
}
  

const mapStateToProps = state => ({
	items: getCurrentFaceItems(state.workspaceState.present),
	html:state.workspaceState.present.html,
	canvas:state.workspaceState.present.canvas,
	activeFace:state.workspaceState.present.activeFace,
	width:state.workspaceState.present.width,
	height:state.workspaceState.present.height,
	zoom:state.workspaceState.present.zoom
});


const mapDispatchToProps = dispatch => ({
	unselectAll: data => dispatch(unselectAll(data)),
	setPosition: data => dispatch(setPosition(data)),
	doDelete: data => dispatch(doDelete(data)),
	doSetHtmlDoc: data => dispatch(doSetHtmlDoc(data)),
	doSetCanvas: data => dispatch(doSetCanvas(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WorkSpace);
  

