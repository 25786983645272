import React , { Component } from 'react';
import { connect } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { setStyle } from 'actions/workspace';

class Police extends Component {

	constructor(props) {
		super(props);
		this.state = {};

	}

	setStyle = (police) => {
		this.props.setStyle({localID: this.props.ele.localID, style : {fontFamily: police }});
	}
    

	render() {

    	return (
			<Tab.Container id="left-tabs-example" defaultActiveKey="Toutes">
				<div className="row">
					<div className="col-md-3">
						<Nav variant="pills" className="flex-column">
							<Nav.Item>
								<Nav.Link eventKey="Toutes">Toutes les polices</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="Classique">Classique</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="Ecriture">Ecriture</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="Gras">Gras</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="International">International</Nav.Link>
							</Nav.Item>
						</Nav>
					</div>
					<div className="col-md-9">
						<Tab.Content>
							<Tab.Pane eventKey="Toutes">
							
                            
								<div className="row">
									<div className="col-md-3">
										<button  onClick={() => { this.setStyle('Gugi, cursive'); }}>
											<span>Gugi</span>
										</button>
									</div>
									<div className="col-md-3">
										<button  onClick={() => { this.setStyle('Roboto, sans-serif'); }}>
											<span>Roboto</span>
										</button>
									</div>
									<div className="col-md-3">
										<button  onClick={() => { this.setStyle('Stylish, sans-serif'); }}>
											<span>Stylish</span>
										</button>
									</div>
									<div className="col-md-3">
										<button  onClick={() => { this.setStyle('Baloo Bhai, cursive'); }}>
											<span>Baloo Bhai</span>
										</button>
									</div>
								</div>

								<div className="row">
									<div className="col-md-3">
										<button  onClick={() => { this.setStyle('Open Sans Condensed, sans-serif'); }}>
											<span>Open Sans Condensed</span>
										</button>
									</div>
									<div className="col-md-3">
										<button  onClick={() => { this.setStyle('Nanum Gothic, sans-serif'); }}>
											<span>Nanum Gothic</span>
										</button>
									</div>
									<div className="col-md-3">
										<button  onClick={() => { this.setStyle('Modak, cursive'); }}>
											<span>Modak</span>
										</button>
									</div>
									<div className="col-md-3">
										<button  onClick={() => { this.setStyle('Indie Flower, cursive'); }}>
											<span>Indie Flower</span>
										</button>
									</div>
								</div>


							</Tab.Pane>
							<Tab.Pane eventKey="Classique">
                            Classique
							</Tab.Pane>
							<Tab.Pane eventKey="Ecriture">
                            Ecriture
							</Tab.Pane>
							<Tab.Pane eventKey="Gras">
                            Gras
							</Tab.Pane>
							<Tab.Pane eventKey="International">
                            International
							</Tab.Pane>
						</Tab.Content>
					</div>
				</div>
			</Tab.Container> 
    	);}
}



const mapDispatchToProps = dispatch => ({
	setStyle: data => dispatch(setStyle(data)),
});
  
export default connect(
	null,
	mapDispatchToProps
)(Police);